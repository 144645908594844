import { ref } from 'vue';

export default function useGenericDialog() {
  const displayModal = ref(false);
  const openDialog = () => {
    displayModal.value = true;
  };

  const closeDialog = (callback) => {
    displayModal.value = false;
    if (typeof callback === 'function') callback();
  };

  return {
    displayModal,
    openDialog,
    closeDialog,
  };
}
