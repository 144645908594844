<template>
  <AdminLayout
    :topbarTheme="theme.topbarTheme"
    :menuTheme="theme.menuTheme"
    @change-component-theme="theme.changeComponentTheme"
    @change-color-scheme="theme.changeColorScheme"
    @topbar-theme="theme.onTopbarThemeChange"
    @menu-theme="theme.onMenuThemeChange"
  />
</template>

<script>
import AdminLayout from './admin.layout.vue';
import { useThemeStore } from '../../store/theme.js';
import { onBeforeMount } from 'vue';

export default {
  name: 'ThemeLayout',
  components: {
    AdminLayout,
  },
  setup() {
    const theme = useThemeStore();
    onBeforeMount(() => theme.init());
    return {
      theme,
    };
  },
};
</script>

<style scoped>
</style>