<template>
  <div :class="containerClass" :data-theme="topbarTheme" @click="onDocumentClick($event)">
    <Toast />
    <div class="layout-content-wrapper">
      <AppTopBar
        :layoutMode="layoutMode"
        :topbarTheme="topbarTheme"
        :menuActive="menuActive"
        :mobileMenuActive="staticMenuMobileActive"
        :sidebarActive="sidebarActive"
        :sidebarStatic="sidebarStatic"
        :topbarUserMenuActive="topbarUserMenuActive"
        :topbarUserMenuClick="topbarUserMenuClick"
        :searchActive="searchActive"
        :searchClick="searchClick"
        :topbarItemClick="topbarItemClick"
        :activeTopbarItem="activeTopbarItem"
        @menu-click="onMenuClick"
        @menuitem-click="onMenuItemClick"
        @root-menuitem-click="onRootMenuItemClick"
        @menu-button-click="onMenuButtonClick"
        @right-menubutton-click="onRightMenuButtonClick"
        @toggle-menu="onToggleMenu"
        @topbar-usermenu-click="onTopbarUserMenuClick"
        @sidebar-mouse-leave="onSidebarMouseLeave"
        @sidebar-mouse-over="onSidebarMouseOver"
        @topbar-search-toggle="onTopbarSearchToggle"
        @topbar-search-click="OnTopbarSearchClick"
        @topbar-search-hide="onTopbarSearchHide"
      >
        <template #buttons>
          <li v-if="hasPermPanel" class="topbar-item relative">
            <Button
              v-tooltip.bottom="'Informes de medidas de protección sin aprobar'"
              icon="fa-solid fa-file-signature"
              iconClass="icon-size-button"
              class="p-button-rounded flex-1 mr-3 button-size-right-panel p-avatar border-transparent hover:surface-200"
              @click="onRightMenuButtonClick"
            />
            <span class="p-badge-success p-badge p-component p-badge-no-gutter" style="position: absolute; top: 6px; right: 4px">
              {{ signatureValue > 100 ? '+99' : signatureValue }}
            </span>
          </li>
          <li class="topbar-item relative">
            <Button
              v-tooltip.bottom="'Notificaciones'"
              icon="fa-solid fa-bell"
              iconClass="icon-size-button"
              class="p-button-rounded p-avatar border-transparent flex-1 mr-3 button-size-right-panel hover:surface-200"
              @click="onNotificationButtonClick"
            />
            <span class="p-badge-success p-badge p-component p-badge-no-gutter" style="position: absolute; top: 6px; right: 4px">
              {{ notificationsValue > 100 ? '+99' : notificationsValue }}
            </span>
          </li>
        </template>
      </AppTopBar>

      <div class="layout-main pt-0" style="padding-top: 5.3rem !important">
        <div class="layout-content">
          <router-view />
        </div>
        <AppFooter :isGradient="false" />
      </div>
    </div>

    <AppRightPanel
      v-if="hasPermPanel"
      v-model:rightMenuClick="rightMenuClick"
      :rightMenuActive="rightMenuActive"
      @right-menu-click="onRightMenuClick"
      @updateSignature="updateSignature"
    ></AppRightPanel>

    <AppNotificationPanel
      v-model:notificationClick="notificationClick"
      :notificationActive="notificationActive"
      @notification-click="onNotificacionClick"
      @updateNotifications="updateNotifications"
    ></AppNotificationPanel>

    <div class="layout-mask modal-in"></div>
  </div>
</template>

<script>
import { DomHandler } from 'primevue/utils';
import EventBus from './../../event-bus';
import AppTopBar from './components/AppTopbar.vue';
import AppFooter from './components/AppFooter.vue';
import AppRightPanel from './components/AppRightPanel.vue';
import { VIEW_MMPP_PANEL_APROBACION } from '../../constants/permissions.const';
import { hasPermission } from '../../helpers/permissions.helper';
import AppNotificationPanel from './components/AppNotificationPanel.vue';
import { workerHelperV1 } from '../../helpers/webpush.helper';

export default {
  props: {
    topbarTheme: String,
    menuTheme: String,
  },
  emits: ['change-color-scheme', 'change-component-theme', 'topbar-theme', 'menu-theme'],
  data() {
    return {
      menuActive: false,
      layoutMode: 'sidebar',
      colorScheme: 'light',
      sidebarActive: false,
      sidebarStatic: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      menuClick: false,
      searchActive: false,
      searchClick: false,
      topbarItemClick: false,
      activeTopbarItem: null,
      userMenuClick: false,
      topbarUserMenuActive: false,
      topbarUserMenuClick: false,
      rightMenuClick: false,
      rightMenuActive: false,
      notificationActive: false,
      notificationClick: false,
      configActive: false,
      configClick: false,
      signatureValue: 0,
      notificationsValue: 0,
    };
  },
  computed: {
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-sidebar': this.layoutMode === 'sidebar',
          'layout-static': this.layoutMode === 'sidebar' && this.sidebarStatic,
          'layout-horizontal': this.layoutMode === 'horizontal',
          'layout-slim': this.layoutMode === 'slim',
          'layout-rightpanel-active': this.rightMenuActive,
          'layout-mobile-active': this.staticMenuMobileActive,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': !this.$primevue.config.ripple,
        },
        'layout-menu-' + this.menuTheme + ' layout-topbar-' + this.topbarTheme,
      ];
    },
    hasPermPanel() {
      return hasPermission([VIEW_MMPP_PANEL_APROBACION]);
    },
  },
  components: {
    AppTopBar,
    AppFooter,
    AppRightPanel,
    AppNotificationPanel,
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    updateSignature(val) {
      this.signatureValue = val;
    },
    updateNotifications(val) {
      this.notificationsValue = val;
    },
    onDocumentClick() {
      if (!this.searchClick && this.searchActive) {
        this.onTopbarSearchHide();
      }

      if (!this.topbarUserMenuClick && this.topbarUserMenuActive) {
        this.topbarUserMenuActive = false;
        this.topbarUserMenuClick = false;
      }

      if (!this.rightMenuClick) {
        this.rightMenuActive = false;
      }
      if (!this.notificationClick) {
        this.notificationActive = false;
      }

      if (!this.menuClick) {
        if (this.isSlim() || this.isHorizontal()) {
          EventBus.emit('reset-active-index');
          this.menuActive = false;
        }

        if (this.staticMenuMobileActive) {
          this.hideOverlayMenu();
        }

        this.unblockBodyScroll();
      }

      if (this.configActive && !this.configClick) {
        this.configActive = false;
      }

      this.searchClick = false;
      this.configClick = false;
      this.userMenuClick = false;
      this.rightMenuClick = false;
      this.notificationClick = false;
      this.topbarUserMenuClick = false;
      this.menuClick = false;
    },
    onToggleMenu(event) {
      this.menuClick = true;
      this.sidebarStatic = !this.sidebarStatic;

      event.preventDefault();
    },
    onMenuClick(event) {
      if (this.isHorizontal() && DomHandler.hasClass(event.target, 'layout-menu-container')) {
        EventBus.emit('reset-active-index');
        this.menuClick = false;
        this.menuActive = false;
      }
      this.menuClick = true;
    },
    onMenuButtonClick(event) {
      this.menuClick = true;
      this.topbarUserMenuActive = false;
      this.rightMenuActive = false;

      if (this.isMobile()) {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
        if (this.staticMenuMobileActive) {
          this.blockBodyScroll();
        } else {
          this.unblockBodyScroll();
        }
      }

      event.preventDefault();
    },
    onMenuItemClick(event) {
      if (!event.item.items) {
        EventBus.emit('reset-active-index');
        this.hideOverlayMenu();
      }
      if (!event.item.items && this.isSlim()) {
        this.menuActive = false;
      }
    },
    onRootMenuItemClick() {
      this.menuActive = !this.menuActive;
    },
    onRightMenuClick() {
      this.rightMenuClick = true;
    },
    onNotificacionClick() {
      this.notificationClick = true;
    },
    onNotificationButtonClick(event) {
      this.notificationActive = !this.notificationActive;
      this.notificationClick = true;
      this.rightMenuClick = false;
      this.rightMenuActive = false;
      event.preventDefault();
    },
    onRightMenuButtonClick(event) {
      this.notificationActive = false;
      this.notificationClick = false;
      this.rightMenuClick = true;
      this.rightMenuActive = !this.rightMenuActive;
      this.hideOverlayMenu();
      event.preventDefault();
    },
    onConfigClick() {
      this.configClick = true;
    },
    onConfigButtonClick() {
      this.configActive = !this.configActive;
      this.configClick = true;
    },
    hideOverlayMenu() {
      this.staticMenuMobileActive = false;
      this.unblockBodyScroll();
    },
    onChangeLayoutMode(mode) {
      this.layoutMode = mode;
      if (mode === 'sidebar') {
        if (this.sidebarStatic) {
          this.sidebarActive = true;
        }
      } else {
        this.sidebarActive = false;

        if (this.topbarTheme !== this.menuTheme) {
          this.$emit('menu-theme', this.topbarTheme);
        }
      }
    },
    onTopbarThemeChange(theme) {
      this.$emit('topbar-theme', theme);
    },
    onMenuThemeChange(theme) {
      this.$emit('menu-theme', theme);
    },
    changeComponentTheme(theme) {
      this.$emit('change-component-theme', theme);
    },
    changeColorScheme(scheme) {
      this.colorScheme = scheme;
      this.$emit('change-color-scheme', scheme);
      this.$appState.colorScheme = scheme;
    },
    onSidebarMouseOver() {
      this.sidebarActive = !this.isMobile();
    },
    onSidebarMouseLeave() {
      this.sidebarActive = false;
    },
    onTopbarSearchToggle() {
      this.searchActive = !this.searchActive;
      this.searchClick = true;
    },
    OnTopbarSearchClick() {
      this.searchClick = true;
    },
    onTopbarSearchHide() {
      this.searchActive = false;
      this.searchClick = false;
    },
    onTopbarUserMenuClick() {
      this.topbarUserMenuActive = !this.topbarUserMenuActive;
      this.topbarUserMenuClick = true;
    },
    blockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.add('blocked-scroll');
      } else {
        document.body.className += ' blocked-scroll';
      }
    },
    unblockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.remove('blocked-scroll');
      } else {
        document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
      }
    },
    isSlim() {
      return this.layoutMode === 'slim';
    },
    isHorizontal() {
      return this.layoutMode === 'horizontal';
    },
    isDesktop() {
      return window.innerWidth > 991;
    },
    isMobile() {
      return window.innerWidth <= 991;
    },
  },
  async mounted() {
    try {
      await workerHelperV1();
    } catch (error) {}
  },
};
</script>

<style lang="scss">
.layout-content {
  padding: 1.25rem;
  padding-top: 0.25rem;
}
.button-size-right-panel {
  height: 35px;
  width: 35px;
}
.icon-size-button {
  padding-left: 4px;
  font-size: 1.25rem;
}
</style>
