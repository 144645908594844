import { decode } from './encode.helper';

export const hasPermission = (perm = []) => {
  const app_info = JSON.parse(decode(localStorage.getItem('app_info')));
  const permissions = app_info?.permisos;
  if (permissions) return !!perm.some((item) => permissions.includes(item.toUpperCase()));
  return false;
};

export const hasFiscalRole = (role = []) => {
  try {
    const roles = JSON.parse(decode(localStorage.getItem('app_info')))?.roles;
    if (roles.includes('FISCAL')) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
