import { ref } from 'vue';
import { base64ToBlob, fileToBlob } from '../helpers/util/pdf.util';
import { getViewPDFRegistroVistaApi, postGeneratePDFApi } from '../modules/catalogos/api/plantillas.api';
import { Swal, Toast } from '../shared/components/core/sweetAlert2';

export default function useWatchFile() {
  const pdfUrl = ref('');
  const signatures = ref();
  const modalPreviewRef = ref();

  const handleViewFile = async (data, obj = {}, pdfService) => {
    Swal.fire({
      title: 'Cargando ...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const isLocalView = !!data.documento;
      let resp;
      let blob;
      if (isLocalView) {
        if (data.content) {
          resp = await onGeneratePdfApi(data, obj);
          blob = base64ToBlob(resp.response.base64);
        } else {
          blob = await fileToBlob(data.documento);
        }
      } else {
        resp = pdfService ? await pdfService : await getViewPDFRegistroVistaApi(data.idFile);
        blob = base64ToBlob(resp.response.data);
        signatures.value = resp.response.registros || [];
      }
      pdfUrl.value = URL.createObjectURL(blob);
      if (modalPreviewRef.value) modalPreviewRef.value.openDialog();
      Swal.close();
    } catch (error) {
      Swal.close();
      Toast.fire({
        icon: 'error',
        title: error.message || 'Ha ocurrido un problema al previsualizar el documento',
      });
    }
  };

  const onGeneratePdfApi = async (data, obj) => {
    try {
      const resp = await postGeneratePDFApi({
        contenido: data.content,
        codigo: obj?.codigoRequerimiento,
        codigoAsistencia: obj?.codigoAsistencia,
        codigoPlantilla: data.codigoPlantilla || null,
        sujetoId: obj?.sujetoId,
        tipoDocumentoId: data.idTipoDocumento || null,
        tipo: 1,
      });
      return resp;
    } catch (error) {
      throw error;
    }
  };

  return {
    pdfUrl,
    signatures,
    modalPreviewRef,
    handleViewFile,
  };
}
