import { createApp, h, reactive } from 'vue';

// #region  components PrimeVue
import PrimeVue from 'primevue/config';
import Avatar from 'primevue/avatar';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import ConfirmationService from 'primevue/confirmationservice';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Menu from 'primevue/menu';
import MultiSelect from 'primevue/multiselect';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import Password from 'primevue/password';
import ProgressBar from 'primevue/progressbar';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import Row from 'primevue/row';
import Skeleton from 'primevue/skeleton';
import StyleClass from 'primevue/styleclass';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import Message from 'primevue/message';
import ToggleButton from 'primevue/togglebutton';

// Plantillas
import MpTemplatesPlugin from 'mp-vue-editor';
import CKEditor from '@ckeditor/ckeditor5-vue';
import '../node_modules/mp-vue-editor/dist/style.css';
// #endregion

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
// #region  file custom
//Components
import Loading from '@/shared/components/core/primevue/loading.component.vue';
import Sidebar from '@/shared/components/core/primevue/sidebar.component.vue';
//Pinia
import { createPinia } from 'pinia';
//locale file primevue
import { locale } from './helpers/util/locale';
//Router
import router from './router';
import App from './App.vue';
import './App.scss';
import 'animate.css';
import 'vue3-pdf-app/dist/icons/main.css';

// #endregion
const app = createApp({
  render() {
    return h(App);
  },
});

app.use(PrimeVue, {
  ripple: true,
  locale,
});

app.config.productionTip = import.meta.env.MODE == 'production';
//Config yup languaje
import { setLocale } from 'yup';
import es from 'yup-es';
setLocale(es);

app.config.globalProperties.$appState = reactive({ colorScheme: 'light', isNewThemeLoaded: false });
export const appState = reactive({ colorScheme: 'light', isNewThemeLoaded: false });

app.use(ConfirmationService);

app.use(ToastService);
app.use(router);
app.use(createPinia());
app.use(CKEditor);
app.use(MpTemplatesPlugin, {
  //Si su proyecto no usa los servicios de primevue true | false
  useServices: false,
  //Si su proyecto no usa las directivas de primevue true | false
  addDirectives: false,
  //Si su proyecto no usa los componentes de primevue true | false
  addPrimeVueComponents: false,
});

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('badge', BadgeDirective);
app.directive('styleclass', StyleClass);

// #region  components global

app.component('Avatar', Avatar);
app.component('Badge', Badge);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('FileUpload', FileUpload);
app.component('Image', Image);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Menu', Menu);
app.component('MultiSelect', MultiSelect);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('Password', Password);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('Row', Row);
app.component('Sidebar', Sidebar);
app.component('Skeleton', Skeleton);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('Toast', Toast);
app.component('Tree', Tree);
app.component('TreeSelect', TreeSelect);
app.component('TreeTable', TreeTable);
app.component('Message', Message);
app.component('ToggleButton', ToggleButton);
// #endregion
app.component('Loading', Loading);

app.mount('#app');
