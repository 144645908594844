import { VIEW_LIST_FUNCIONARIOS } from '../../../constants/permissions.const';
import { hasPermissionMiddleware } from '../../../router/auth.guard';

const routes = [
  {
    path: '',
    name: 'funcionarios',
    redirect: { name: 'funcionariosPage' },
  },
  {
    path: 'list',
    name: 'funcionariosPage',
    meta: { title: 'Bandeja de funcionarios - DPVTMMP' },
    component: () => import('../pages/FuncionariosPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_FUNCIONARIOS])],
  },
];

export default routes;
