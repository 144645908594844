<template>
  <div id="layout-config" @click="onNotificacionClick(true)">
    <div class="layout-config layout-rightpanel-config rightpanel-wrapper-config rightpanel-wrapper" :class="{ 'layout-config-active': notificationActive }">
      <LoadingComponent v-if="isLoading" />
      <div class="grid grid-nogutter">
        <div class="col-12">
          <div class="text-sm uppercase text-center font-semibold p-2 card m-0"><span>NOTIFICACIONES</span></div>
        </div>
        <div v-if="notifications.length > 0" class="field col-12 flex flex-column">
          <div class="w-full text-sm text-center card card-notification m-0" v-for="(notif, idx) in notifications" :key="idx">
            <div class="flex justify-content-between border-1 border-200 border-round-sm p-2 text-sm">
              <div class="flex gap-2 align-items-center font-semibold">
                <i class="text-primary text-lg pi pi-calendar" />
                <span>Requerimiento</span>
              </div>
              <span>{{ notif.codigo }} </span>
            </div>

            <div class="flex justify-content-between border-1 border-200 border-round-sm p-2 text-sm">
              <div class="flex gap-2 align-items-center font-semibold">
                <i class="text-primary text-lg pi pi-calendar" />
                <span>Sistema</span>
              </div>
              <span>{{ notif.tipo === 1 ? 'Medidas de protección' : 'Asistencias' }} </span>
            </div>
            <div class="flex justify-content-between border-1 border-200 border-round-sm p-2 text-sm">
              <div class="flex gap-2 align-items-center font-semibold">
                <i class="text-primary text-lg pi pi-calendar" />
                <span>Fecha</span>
              </div>
              <span class="text-sm">{{ DateTime.fromISO(notif.fechaCreacion).setLocale('es-ES').toFormat('dd/MM/yyyy HH:mm') }} </span>
            </div>
            <div class="flex justify-content-between border-1 border-200 border-round-sm p-2 text-sm gap-2 align-items-center">
              <div class="text-left">
                <span>{{ notif.titulo }} </span>
              </div>
            </div>
            <div class="text-center p-2 flex gap-2 justify-content-between">
              <Button icon="pi pi-check" v-tooltip.bottom="'Marcar como leído'" class="text-sm p-button-success" @click="changeStatus(notif)" />
              <Button icon="fa fa-arrow-right" v-tooltip.bottom="'Ir'" class="p-button-help text-sm" @click="goToPage(notif)" />
            </div>
          </div>
        </div>
        <div v-else class="field col-12 card">
          <p class="text-sm text-center font-semibold py-5">No tiene notificaciones nuevas</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppNotificationPanel',
};
</script>

<script setup>
import { DateTime } from 'luxon';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { changeNotificationStatusApi, getNotificationsApi } from '../../../api/shared.api';
import LoadingComponent from '../../components/core/primevue/loading.component.vue';
import { Toast } from '../../components/core/sweetAlert2';

const emit = defineEmits(['notification-click', 'update:notificacionClick', 'updateNotifications']);

const props = defineProps({
  notificacionClick: Boolean,
  notificationActive: Boolean,
});

onMounted(() => {
  onLoadNotifications();
});

onUnmounted(() => {
  clearInterval(intervalFunc.value);
});

const notifications = ref([]);
const isLoading = ref(false);

watch(
  () => props.notificationActive,
  (current) => {
    if (current) {
      onLoadNotifications();
    }
  }
);

const onNotificacionClick = (val) => {
  emit('notification-click', val);
};

const onLoadNotifications = async () => {
  try {
    isLoading.value = true;
    const resp = await getNotificationsApi();
    notifications.value = resp.response;
    if (resp.response?.length) {
      emit('updateNotifications', resp.response.length);
    }
  } catch (error) {
    Toast.fire({
      icon: 'error',
      title: error.message || 'Ha ocurrido un problema al conseguir los informes por aprobar del usuario',
    });
  } finally {
    isLoading.value = false;
  }
};

const intervalFunc = ref(setInterval(onLoadNotifications, 300000));

const changeStatus = async (notification) => {
  try {
    isLoading.value = true;
    changeNotificationStatusApi(notification.id);
    notifications.value = notifications.value.filter((notif) => notif.id !== notification.id);
    emit('updateNotifications', notifications.value.length);
  } catch (error) {
    //nothing to do
  } finally {
    setTimeout(() => {
      isLoading.value = false;
    }, 150);
  }
};

const goToPage = async (notification) => {
  window.location.href = notification.url;
};
</script>
<style lang="scss">
.layout-config.layout-rightpanel-config {
  padding: 1rem 0.5rem;
}
.card.card-notification {
  border: none;
  border-bottom: 1px solid var(--surface-100);
  border-radius: 0 !important;
}
</style>
