import { defineStore } from 'pinia';
import { appState } from '@/main';

export const useThemeStore = defineStore('theme', {
  state: () => {
    return {
      darkMode: 0,
      topbarTheme: 'light',
      menuTheme: 'light',
      colorScheme: 'avocado',
      isLoading: false,
    };
  },
  getters: {
    theme(state) {
      switch (state.colorScheme) {
        case 'turquoise':
          return 'Turquesa';
        case 'blue':
          return 'Azul';
        case 'purple':
          return 'Morado';
        case 'green':
          return 'Verde';
        case 'avocado':
          return 'Palta';
        case 'orange':
          return 'Naranja';
        case 'red':
          return 'Rojo';
        case 'yellow':
          return 'Amarillo';
      }
    },
  },
  actions: {
    init() {
      const theme_default = window.matchMedia('(prefers-color-scheme: dark)').matches ? 1 : 0;
      const darkMode = parseInt(localStorage.getItem('activeDarkMode') || theme_default);
      this.colorScheme = localStorage.getItem('scheme-color') || 'blue';
      this.darkMode = darkMode;
      this.topbarTheme = darkMode == 1 ? 'dark' : 'light';
      this.menuTheme = darkMode == 1 ? 'dark' : 'light';
      let scheme = darkMode == 1 ? 'dark' : 'light';
      document.firstElementChild.setAttribute('data-theme', scheme);
      this.changeStyleSheetUrl('layout-css', `/layout/css/layout-${scheme}.css`, 1);
      this.changeStyleSheetUrl('theme-css', `/theme/${this.colorScheme}/theme-${scheme}.css`, 1);
      this.changeStyleSheetUrl('theme-sweetalert2', `/sweetalert2/${scheme}.css`);
    },
    onThemeList() {
      return [
        { label: 'Turquesa', code: 'turquoise', command: () => this.onChangeSchemaColor('turquoise') },
        { label: 'Azul', code: 'blue', command: () => this.onChangeSchemaColor('blue') },
        { label: 'Morado', code: 'purple', command: () => this.onChangeSchemaColor('purple') },
        { label: 'Verde', code: 'green', command: () => this.onChangeSchemaColor('green') },
        { label: 'Palta', code: 'avocado', command: () => this.onChangeSchemaColor('avocado') },
        { label: 'Naranja', code: 'orange', command: () => this.onChangeSchemaColor('orange') },
        { label: 'Rojo', code: 'red', command: () => this.onChangeSchemaColor('red') },
        { label: 'Amarillo', code: 'yellow', command: () => this.onChangeSchemaColor('yellow') },
      ];
    },
    onTopbarThemeChange(theme) {
      this.topbarTheme = theme;
    },
    onMenuThemeChange(theme) {
      this.menuTheme = theme;
    },
    onChangeSchemaColor(color) {
      this.colorScheme = color;
      this.changeStyleSheetUrl('theme-css', `/theme/${this.colorScheme}/theme-${this.menuTheme}.css`, 1);
      localStorage.setItem('scheme-color', this.colorScheme);
    },
    changeColorScheme(scheme) {
      this.topbarTheme = scheme;
      this.menuTheme = scheme;
      document.firstElementChild.setAttribute('data-theme', scheme);
      this.changeStyleSheetUrl('layout-css', `/layout/css/layout-${scheme}.css`, 1);
      this.changeStyleSheetUrl('theme-css', `/theme/${this.colorScheme}/theme-${scheme}.css`, 1);
      this.changeStyleSheetUrl('theme-sweetalert2', `/sweetalert2/${scheme}.css`);

      this.isLoading = true;

      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
    changeDarkMode(mode) {
      if (typeof mode === 'number') {
        this.darkMode = mode;
      } else {
        this.darkMode = 1 - this.darkMode;
      }
      localStorage.setItem('activeDarkMode', this.darkMode);
      this.changeColorScheme(this.darkMode ? 'dark' : 'light');
    },
    changeStyleSheetUrl(id, href) {
      let element = document.getElementById(id);
      if (element == null) {
        const css = document.createElement('link');
        css.setAttribute('id', id);
        css.setAttribute('rel', 'stylesheet');
        css.setAttribute('href', href);
        document.getElementsByTagName('head')[0].appendChild(css);
        element = document.getElementById(id);
      }
      this.replaceLink(element, href, () => {
        appState.isNewThemeLoaded = true;
      });
    },
    replaceLink(linkElement, href, callback) {
      if (this.isIE()) {
        linkElement.setAttribute('href', href);
      } else {
        const id = linkElement.getAttribute('id');
        const cloneLinkElement = linkElement.cloneNode(true);

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

        cloneLinkElement.addEventListener('load', () => {
          linkElement.remove();
          cloneLinkElement.setAttribute('id', id);

          if (callback) {
            callback();
          }
        });
      }
    },
    isIE() {
      return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    },
  },
});
