import { postWebPushApi } from '../api/webpush.api';

export const appWebpushPublicVapidKey =
  import.meta.env.VITE_WEBPUSH_KEY || 'BIqIzX8AtnnJbbt4uy-EvIzS9fHrDxGs2hkXUjcyByOXXH6Qm2QTM74XBSsNUnDvY8GzqhG4W7Lb2nsN7l2mRDQ';
export const workerURL = '/js/worker.service.js';
export const workerURLScope = '/js/';

export const subscriptionServiceV1 = async (subscription) => {
  let response = {
    error: true,
    message: 'Existe problemas con el servicio subscriptionServiceV1.',
    response: {},
    status: 422,
  };

  const data = {
    subscription,
  };

  response = await postWebPushApi(data);

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const workerHelperV1 = async () => {
  let response = {
    error: true,
    message: 'Existe problemas con el helper workerHelperV1.',
    response: {},
    status: 422,
  };

  if (checkServiceWorkerPushEnabledHelperV1()) {
    await enableServiceWorkerHelperV1()
      .then((resp) => {
        response = resp;
      })
      .catch((err) => {
        response = err;
      });

    if (!response.error) {
      await initialiseWorkderV1(response.response)
        .then((resp) => {
          response = resp;
        })
        .catch((err) => {
          response = err;
        });
    }
  } else {
    response.message = 'Las notificaciones web no están soportadas por el navegador.';
  }

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const checkServiceWorkerPushEnabledHelperV1 = () => 'serviceWorker' in navigator && 'PushManager' in window;

export const enableServiceWorkerHelperV1 = async () => {
  let response = {
    error: true,
    message: 'Existe problemas con el helper enableServiceWorkerHelperV1.',
    response: {},
    status: 422,
  };

  await navigator.serviceWorker
    .register(workerURL, { scope: workerURLScope })
    .then((resp) => {
      response.error = false;
      response.message = 'Se registro al servicio worker.';
      response.response = resp;
      response.status = 200;
    })
    .catch((err) => {
      response.message = 'Error en el servicio worker.';
      response.response = err;
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const initialiseWorkderV1 = async (workerRegister) => {
  let response = {
    error: true,
    message: 'Existe problemas con el helper initialiseWorkderV1.',
    response: {},
    status: 422,
  };
  let isSubscribed = false;
  let subscription = null;

  await workerRegister.pushManager
    .getSubscription()
    .then((resp) => {
      isSubscribed = !(resp === null);

      if (isSubscribed) {
        subscription = resp;
      }
    })
    .catch((err) => {
      response.message = 'Error al leer la suscripción del servicio worker.';
      response.response = err;
    });

  if (!isSubscribed) {
    await subscribeUserHelperV1(workerRegister)
      .then((resp) => {
        response = resp;
      })
      .catch((err) => {
        response = err;
      });

    if (!response.error) {
      subscription = response.response;
      isSubscribed = true;
    }
  }

  if (isSubscribed) {
    await sendSubcriptionObjectHelperV1(subscription)
      .then((resp) => {
        response = resp;
      })
      .catch((err) => {
        response = err;
      });
  }

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const subscribeUserHelperV1 = async (workerRegister) => {
  let response = {
    error: true,
    message: 'Existe problemas con el helper subscribeUserHelperV1.',
    response: {},
    status: 422,
  };

  const applicationServerKey = urlB64ToUint8ArrayHelperV1(appWebpushPublicVapidKey);

  await workerRegister.pushManager
    .subscribe({
      userVisibleOnly: true,
      applicationServerKey,
    })
    .then((resp) => {
      response.error = false;
      response.message = 'Usuario suscritó.';
      response.response = resp;
      response.status = 200;
    })
    .catch((err) => {
      response.message = 'Fallo al realizar la suscripción.';
      response.response = err;
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const sendSubcriptionObjectHelperV1 = async (subscription) => {
  let response = {
    error: true,
    message: 'Existe problemas con el helper sendSubcriptionObjectHelperV1.',
    response: {},
    status: 422,
  };

  await subscriptionServiceV1(subscription)
    .then((resp) => {
      response = resp;
    })
    .catch((err) => {
      response = err;
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const urlB64ToUint8ArrayHelperV1 = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
