<template>
  <div class="layout-topbar">
    <div class="layout-topbar-wrapper">
      <div class="layout-topbar-left">
        <a tabindex="0" class="menu-button" @click="onMenuButtonClick">
          <i class="pi pi-bars"></i>
        </a>
        <router-link id="logo-link" class="layout-topbar-logo icon-inst" to="/">
          <img src="/assets/img/logo_bol.png" alt="admin-layout" />
        </router-link>
        <span v-if="!sidebarActive" :class="{ 'm-custom': !sidebarActive }" class="hidden sm:block" style="font-size: 1rem; font-weight: bold">{{ app }}</span>
      </div>

      <AppMenu
        :layoutMode="layoutMode"
        :sidebarActive="sidebarActive"
        :sidebarStatic="sidebarStatic"
        :menuActive="menuActive"
        :mobileMenuActive="mobileMenuActive"
        @sidebar-mouse-leave="onSidebarMouseLeave"
        @sidebar-mouse-over="onSidebarMouseOver"
        @toggle-menu="onToggleMenu"
        @menu-click="onMenuClick"
        @menuitem-click="onMenuItemClick"
        @root-menuitem-click="onRootMenuItemClick"
      />

      <div class="layout-topbar-right" style="padding-right: 5px">
        <ul class="layout-topbar-actions">
          <slot name="buttons"></slot>
          <li ref="layout-mode" class="topbar-item">
            <ThemeButton />
          </li>
          <li ref="profile" class="topbar-item user-profile ml-2" :class="{ 'active-topmenuitem fadeInDown': topbarUserMenuActive }">
            <SesionUsuarioMenu />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import AppMenu from './AppMenu.vue';
import { useThemeStore } from '@/store/theme';
import ThemeButton from './ThemeButton.vue';
import SesionUsuarioMenu from './SesionUsuarioMenu.vue';
import { PROYECT_NAME } from '../../../helpers/constants.js';

export default {
  name: 'AppTopbar',
  emits: [
    'menu-click',
    'menuitem-click',
    'root-menuitem-click',
    'menu-button-click',
    'toggle-menu',
    'right-menubutton-click',
    'sidebar-mouse-over',
    'sidebar-mouse-leave',
    'topbar-search-toggle',
    'topbar-search-click',
    'topbar-search-hide',
    'topbar-usermenu-click',
    'update:searchClick',
    'change-dark-mode',
  ],
  props: {
    searchActive: Boolean,
    searchClick: Boolean,
    topbarItemClick: Boolean,
    topbarUserMenuActive: Boolean,
    topbarUserMenuClick: Boolean,
    activeTopbarItem: String,
    sidebarActive: Boolean,
    sidebarStatic: Boolean,
    layoutMode: String,
    topbarTheme: String,
    menuActive: Boolean,
    mobileMenuActive: Boolean,
  },
  setup() {
    const theme = useThemeStore();
    return {
      theme,
      app: PROYECT_NAME,
    };
  },
  unmounted() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  },
  methods: {
    onMenuClick(event) {
      this.$emit('menu-click', event);
    },
    onMenuItemClick(event) {
      this.$emit('menuitem-click', event);
    },
    onRootMenuItemClick(event) {
      this.$emit('root-menuitem-click', event);
    },
    onMenuButtonClick(event) {
      this.$emit('menu-button-click', event);
    },
    onToggleMenu(event) {
      this.$emit('toggle-menu', event);
    },
    onTopbarSearchToggle(event) {
      this.$emit('topbar-search-toggle', event);
      this.onSearchFocus();
    },
    onTopbarSearchClick(event) {
      this.$emit('topbar-search-click', event);
    },
    onInputKeydown(event) {
      const key = event.which;

      //escape, tab and enter
      if (key === 27 || key === 9 || key === 13) {
        this.$emit('topbar-search-hide', event);
      }
    },
    onTopbarUserMenuClick(event) {
      this.$emit('topbar-usermenu-click', event);
    },
    onRightMenuButtonClick(event) {
      this.$emit('right-menubutton-click', event);
    },
    onSidebarMouseOver() {
      this.$emit('sidebar-mouse-over');
    },
    onSidebarMouseLeave() {
      this.$emit('sidebar-mouse-leave');
    },
    onSearchFocus() {
      if (window.innerWidth >= 576) {
        this.$refs.desktopInput.$el.focus();
      } else {
        this.$nextTick(function () {
          this.$refs.phoneInput.$el.focus();
        });
      }
    },
    onChangeDarkMode() {
      this.theme.darkMode = 1 - this.theme.darkMode;
      this.$emit('change-dark-mode', this.theme.darkMode);
    },
  },
  components: {
    AppMenu,
    ThemeButton,
    SesionUsuarioMenu,
  },
};
</script>

<style lang="scss" scoped>
.icon-danger {
  background-color: red !important;
}

.icon-dark-mode {
  font-size: large;
  padding-right: 5px;
}

.icon-inst {
  height: 35px !important;
  width: 35px !important;

  img {
    width: 100%;
    height: 100% !important;
  }
}
.m-custom {
  margin-left: 4vw;
}
</style>
