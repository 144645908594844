import { INSERT_DOCUMENT, VIEW_ASISTENCIA, VIEW_LIST_ASISTENCIAS, VIEW_LIST_ASISTENCIAS_FUNCIONARIOS } from '../../../constants/permissions.const';
import { hasPermissionMiddleware } from '../../../router/auth.guard';

const routes = [
  {
    path: '',
    name: 'asistencias',
    redirect: { name: 'bandejaAsistenciasPage' },
  },
  {
    path: 'list',
    name: 'bandejaAsistenciasPage',
    meta: { title: 'Bandeja de asistencias - UPAVT' },
    component: () => import('../pages/BandejaAsistenciasPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_ASISTENCIAS])],
  },
  {
    path: 'asistencia_caso/:id?',
    name: 'requerimientoAsistPage',
    meta: { title: 'Requerimientos - UPAVT' },
    component: () => import('../pages/RequerimientoAsistPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_ASISTENCIAS])],
  },
  {
    path: 'requerimiento/',
    name: 'asistenciaCreatePage',
    meta: { title: 'Crear requerimientos - UPAVT' },
    component: () => import('../pages/CreateReqAsistenciaPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_ASISTENCIAS])],
  },
  {
    path: 'asistencia_caso/ver',
    name: 'asistenciaPreviewPage',
    meta: { title: 'Ver requerimiento - UPAVT' },
    component: () => import('../pages/PreviewAsistenciaPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_ASISTENCIA])],
  },
  {
    path: 'asistencia_caso/upavt',
    name: 'asistenciaUpavtFunc',
    meta: { title: 'Ver requerimientos - UPAVT' },
    component: () => import('../pages/AsistenciasUpavtFuncPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_ASISTENCIAS_FUNCIONARIOS])],
  },
  {
    path: 'asistencia_caso/upavt/informe/:token',
    name: 'asistenciaUpavtFileFunc',
    meta: { title: 'Añadir informe - UPAVT' },
    component: () => import('../pages/AsistenciaAddInformePage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([INSERT_DOCUMENT])],
  },
  {
    path: 'asistencia_caso/upavt/reprogramaciones',
    name: 'asistenciaUpavtReprog',
    meta: { title: 'Bandeja de reprogramaciones - UPAVT' },
    component: () => import('../pages/ReprogramacionesPage.vue'),
    // beforeEnter: [() => hasPermissionMiddleware([INSERT_DOCUMENT])],
  },
  {
    path: 'asistencia_caso/upavt/reprogramacion/:token',
    name: 'asistenciaUpavtReprogForm',
    meta: { title: 'Reprogramar actividad - UPAVT' },
    component: () => import('../pages/ReprogAsistenciaPage.vue'),
    // beforeEnter: [() => hasPermissionMiddleware([INSERT_DOCUMENT])],
  },
];

export default routes;
