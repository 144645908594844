import { VIEW_LIST_PERSONAS } from '../../../constants/permissions.const';
import { hasPermissionMiddleware } from '../../../router/auth.guard';

const routes = [
  {
    path: '',
    name: 'personas',
    redirect: { name: 'personasPage' },
  },
  {
    path: 'personas',
    name: 'personasPage',
    meta: { title: 'Personas - DPVTMMP' },
    component: () => import('../pages/PersonasPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_PERSONAS])],
  },
];

export default routes;
