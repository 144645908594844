<template>
  <div style="z-index: 10000">
    <div class="m-0 px-1">
      <a @click="toggle" aria-controls="overlay_menu" class="usuario-container">
        <div class="hidden md:block mr-3">
          <div class="avatar-data font-semibold">
            {{ authStore?.userFullName }}
          </div>
          <div class="avatar-data">
            {{ authStore?.cargo || null }}
          </div>
        </div>
        <Avatar class="avatar" :image="fotoRef" @error="onImageError" alt="ls" shape="circle" />
      </a>
      <Menu v-if="showMenu" id="overlay_menu" ref="menu" :model="items" :popup="true" class="px-2">
        <template #item="{ item }">
          <div class="flex cursor-pointer" @click="item.command">
            <div class="flex-none flex align-items-start justify-content-start font-bold text-white border-round">
              <i :class="item.icon" style="padding: 0.6rem; border-radius: 0.3rem; color: var(--text-color) !important" />
            </div>
            <div class="flex-none flex align-items-center justify-content-center font-bold text-white border-round font-custom">
              {{ item.label }}
            </div>
          </div>
        </template>
      </Menu>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useAuthStore } from '../../../modules/auth/store/authStore';
import { useToast } from 'primevue/usetoast';
import { genUrlFoto } from '@/helpers/foto.helper.js';
import { onLogoutService } from '../../../modules/auth/api/auth.api';
import { Swal } from '@/shared/components/core/sweetAlert2';
import { decode } from '../../../helpers/encode.helper';
import { APP_URL, CIUDADANIA_DIGITAL_URL } from '../../../helpers/constants';
import { onImageError } from '../../../helpers/foto.helper';
export default {
  name: 'SesionUsuarioMenu',
  props: {
    usuario: {
      type: Object,
      default: undefined,
    },
    showMenu: {
      type: Boolean,
      default: true,
    },
    logoutFiscal: {
      type: Function,
    },
  },
  setup(props) {
    const toast = useToast();
    const authStore = useAuthStore();
    const menu = ref();
    const items = ref([
      // {
      //   label: 'Perfil',
      //   icon: 'pi pi-id-card',
      //   type: 'info',
      //   command: () => {
      //     toast.add({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
      //   },
      // },
      {
        label: 'Cerrar Sesión',
        icon: 'icon-danger pi pi-sign-out',
        type: 'danger',
        command: () => {
          if (props.logoutFiscal) {
            props.logoutFiscal();
          } else {
            onLogout();
          }
        },
      },
    ]);

    const fotoRef = ref(genUrlFoto(authStore.user));

    const onImageError = () => {
      fotoRef.value = '/assets/img/default.svg';
    };

    const toggle = (event) => {
      menu.value.toggle(event);
    };

    const onLogout = async () => {
      Swal.fire({
        html: 'Cargando ...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const token = localStorage.getItem('token');
      const binnacleId = decode(localStorage.getItem('binnacleId'));
      try {
        await onLogoutService(token);
      } catch (error) {
        // Do nothing
      } finally {
        Swal.close();
        localStorage.removeItem('token');
        localStorage.removeItem('binnacleId');
        if (binnacleId) {
          const urlRedirect = `${CIUDADANIA_DIGITAL_URL}/?urlRedirectLogout=${APP_URL}/&binnacleId=${binnacleId}`;
          window.location.href = urlRedirect;
        } else {
          window.location.href = APP_URL;
        }
      }
    };

    return {
      menu,
      toggle,
      items,
      authStore,
      fotoRef,
      onImageError,
    };
  },
};
</script>

<style lang="scss" scoped>
.avatar-data {
  text-align: right;
  font-size: 0.9rem;
}

.usuario-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
}

.avatar {
  width: 2.5rem;
  height: 2.5rem;
}
::v-deep(.avatar) {
  & img {
    object-fit: cover;
  }
}

.font-custom {
  // font-family: 'TechovierThin';
  font-size: 0.75rem;
  color: var(--text-color) !important;
}
</style>
