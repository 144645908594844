import { VIEW_DASHBOARD, VIEW_DASHBOARD_GESSEL, VIEW_DASHBOARD_MMPP } from '../../../constants/permissions.const';
import { hasPermissionMiddleware } from '../../../router/auth.guard';

const routes = [
  {
    path: '',
    name: 'dashboard',
    redirect: { name: 'dashboardPage' },
  },
  {
    path: '/dashboard_asistencias',
    name: 'dashboardPage',
    meta: { title: 'Asistencias - DPVTMMP' },
    component: () => import('../pages/DashboardPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_DASHBOARD])],
  },
  {
    path: '/dashboard_mmpp',
    name: 'dashboardMmppPage',
    meta: { title: 'Medidas de protección - DPVTMMP' },
    component: () => import('../pages/DashboardMmppPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_DASHBOARD_MMPP])],
  },
  {
    path: '/dashboard_gesell',
    name: 'dashboardGesellPage',
    meta: { title: 'Gesell - DPVTMMP' },
    component: () => import('../pages/DashboardGesselPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_DASHBOARD_GESSEL])],
  },
];

export default routes;
