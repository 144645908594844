import axios from 'axios';
import { SEGURIDAD_API_URL } from '../helpers/constants';

export const seguridadInstance = axios.create({
  baseURL: SEGURIDAD_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

seguridadInstance.interceptors.request.use(
  (config) => {
    if (config.url !== '/login') {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
