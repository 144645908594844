<template>
  <div class="layout-rightpanel" @click="onRightMenuClick(true)">
    <div class="rightpanel-wrapper">
      <LoadingComponent v-if="isLoading" />
      <div class="grid grid-nogutter">
        <div class="field col-12 card mb-0 p-2">
          <p class="text-sm text-center font-bold uppercase">INFORMES DE SEGUIMIENTO DE MMPP SIN APROBAR</p>
        </div>
        <div v-if="currentFunc" class="field col-12 card py-3 mb-2">
          <div class="mb-2">
            <p class="font-semibold uppercase text-sm text-center p-0">Usuario</p>
          </div>
          <!-- FUNCIONARIO ACTUAL -->
          <div class="flex gap-2 justify-content-center text-sm">
            <img :src="currentFunc.fotoUrl" class="profile_image" :onerror="(ev) => onImageError(ev)" alt="Image" />
            <div class="flex flex-column justify-content-center">
              <p class="m-0">{{ currentFunc.nombreCompleto }}</p>
              <p class="m-0">{{ currentFunc.ci }}</p>
              <p v-if="currentFunc.cargo" class="m-0">{{ currentFunc.cargo }}</p>
            </div>
          </div>
        </div>
        <div v-if="informes.length > 0" class="field col-12 flex flex-column gap-2">
          <div class="text-sm uppercase text-center font-semibold p-2 card m-0"><span>Documentos</span></div>
          <div class="w-full text-sm text-center card m-0" v-for="(informe, idx) in informes" :key="idx">
            <div class="flex justify-content-between border-1 border-200 border-round-sm p-2 text-sm">
              <div class="flex gap-2 align-items-center font-semibold">
                <i class="text-primary text-lg pi pi-calendar" />
                <span>Requerimiento</span>
              </div>
              <span>{{ informe.codigoRequerimiento }} </span>
            </div>
            <div class="flex justify-content-between border-1 border-200 border-round-sm p-2 text-sm">
              <div class="flex gap-2 align-items-center font-semibold">
                <i class="text-primary text-lg pi pi-calendar" />
                <span>Documento</span>
              </div>
              <span>{{ informe.nombreTipoDocumento }} </span>
            </div>
            <div class="border-1 border-200 border-round-sm text-center p-2 flex gap-2 justify-content-center">
              <Button icon="pi pi-eye" v-tooltip="'Ver documento'" class="text-sm" label="Ver" @click="handleViewFile(informe)" />
              <Button class="p-button-help" icon="fa fa-signature" v-tooltip="'Aprobar documento mediante ciudadanía digital'" @click="signFile(informe)" />
            </div>
          </div>
        </div>
        <div v-else class="field col-12 card">
          <p class="text-sm text-center font-semibold">Sin informes por aprobar</p>
        </div>
      </div>
    </div>
    <DialogPreviewPDF ref="modalPreviewRef" v-model="pdfUrl" :signatures="signatures" />
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from '@vue/runtime-core';
import { useAuthStore } from '../../../modules/auth/store/authStore';
import { Toast } from '../../components/core/sweetAlert2';
import { getFilesToApproveApi } from '../../../api/shared.api';
import LoadingComponent from '../../components/core/primevue/loading.component.vue';
import useCiudadaniaHook from '../../../hooks/useCiudadaniaHook';
import DialogPreviewPDF from '../../../modules/requerimientos/components/DialogPreviewPDF.vue';
import { onImageError } from '../../../helpers/foto.helper';
import useWatchFile from '../../../hooks/useWatchFile';
const emit = defineEmits(['right-menu-click', 'update:rightMenuClick', 'updateSignature']);
const props = defineProps({
  rightMenuClick: Boolean,
  rightMenuActive: Boolean,
});

const { handleSignFile } = useCiudadaniaHook();

const loadFilesToAprrove = async () => {
  try {
    isLoading.value = true;
    const resp = await getFilesToApproveApi();
    informes.value = resp.response;
    if (resp.response?.length) {
      emit('updateSignature', resp.response.length);
    }
  } catch (error) {
    Toast.fire({
      icon: 'error',
      title: error.message || 'Ha ocurrido un problema al conseguir los informes por aprobar del usuario',
    });
  } finally {
    isLoading.value = false;
  }
};

watch(
  () => props.rightMenuActive,
  (current) => {
    if (current) {
      loadFilesToAprrove();
    }
  }
);

const informes = ref([]);
const currentFunc = ref([]);
const intervalFunc = ref(setInterval(loadFilesToAprrove, 300000));
const isLoading = ref(false);

const {
  //values
  pdfUrl,
  signatures,
  modalPreviewRef,
  handleViewFile,
} = useWatchFile();
const authStore = useAuthStore();

const onRightMenuClick = (val) => {
  emit('update:rightMenuClick', val);
};

const signFile = async (data) => {
  const binnacleId = authStore.getBinnacleId;
  if (binnacleId) {
    const result = await Swal.fire({
      title: '¿Está seguro/a que desea aprobar el documento?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
      cancelButtonText: 'Cancelar',
    });
    if (result.isConfirmed) {
      handleSignFile(data.idFile, `${window.location.origin}${window.location.pathname}`);
    }
  } else {
    handleSignFile(data.idFile, `${window.location.origin}${window.location.pathname}`);
  }
};

onMounted(() => {
  currentFunc.value = {
    nombreCompleto: authStore.getUser.nombreCompleto,
    ci: authStore.getUser.ci,
    cargo: authStore.getUser.cargo || null,
    fotoUrl: `${import.meta.env.VITE_RRHH_API}/api/personas/fotosegip/${authStore.getUser.fotoRuta}`,
  };
  loadFilesToAprrove();
});

onUnmounted(() => {
  if (intervalFunc.value) {
    clearInterval(intervalFunc.value);
  }
});
</script>

<script>
export default {
  name: 'AppRightPanel',
  components: { LoadingComponent, DialogPreviewPDF },
};
</script>
<style lang="scss">
.layout-rightpanel .rightpanel-wrapper {
  padding: 1rem 0.5rem;
}
</style>
