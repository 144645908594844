import axios from 'axios';
import { APP_URL } from '../helpers/constants';
import { refreshTokenService } from '../modules/auth/api/auth.api';

export const victimasInstance = axios.create({
  baseURL: `${import.meta.env.VITE_VICTIMAS_API}`,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

victimasInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// victimasInstance.interceptors.response.use(
//   (res) => res,
//   async (err) => {
//     const originalConfig = err.config;
//     if (err.response) {
//       //Access token was expired
//       if (err.response.data.status === 401 && !originalConfig._retry) {
//         originalConfig._retry = true;
//         const oldToken = localStorage.getItem('token');
//         try {
//           const resp = await refreshTokenService(oldToken);
//           const token = resp.response;
//           localStorage.setItem('token', token);
//           return victimasInstance(originalConfig);
//         } catch (refreshError) {
//           localStorage.removeItem('app_info');
//           localStorage.removeItem('token');
//           localStorage.removeItem('binnacleId');
//           const isJl1Url = window.location.pathname.includes('/jl/req_fiscal');
//           if (isJl1Url) {
//             window.location.href = `${APP_URL}/login/jl/sesion_finalizada`;
//           } else {
//             window.location.href = APP_URL;
//           }
//         }
//       }
//       return Promise.reject(err);
//     }
//   }
// );

//REMOVIDO EL REFRESH
victimasInstance.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (err.response) {
      //Access token was expired
      if (err.response.data.status === 401) {
        const oldToken = localStorage.getItem('token');

        localStorage.removeItem('app_info');
        localStorage.removeItem('token');
        localStorage.removeItem('binnacleId');
        const isJl1Url = window.location.pathname.includes('/jl/req_fiscal');
        if (isJl1Url) {
          window.location.href = `${APP_URL}/login/jl/sesion_finalizada`;
        } else {
          window.location.href = APP_URL;
        }
      }
      return Promise.reject(err);
    }
  }
);
