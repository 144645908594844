import { APPROVE_EVALUACION_DIRECTOR, INSERT_EVALUACION, VIEW_LIST_MMPPS, VIEW_MMPP } from '../../../constants/permissions.const';
import { hasPermissionMiddleware } from '../../../router/auth.guard';

const routes = [
  {
    path: '',
    name: 'mmpp',
    redirect: { name: 'mmppPage' },
  },
  {
    path: 'list',
    name: 'mmppPage',
    meta: { title: 'Bandeja de mmpp - DPVTMMP' },
    component: () => import('../pages/mmppPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_MMPPS])],
  },
  {
    path: 'add_files/:token',
    name: 'mmppAddFilesPage',
    meta: { title: 'Bandeja de mmpp - DPVTMMP' },
    component: () => import('../pages/mmppAddFilesPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([APPROVE_EVALUACION_DIRECTOR])],
  },
  {
    path: 'preview',
    name: 'mmppPreviewPage',
    meta: { title: 'Bandeja de mmpp - DPVTMMP' },
    component: () => import('../pages/mmppPreviewPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_MMPP])],
  },
  {
    path: 'approve',
    name: 'mmppApprovePage',
    meta: { title: 'Bandeja de mmpp - DPVTMMP' },
    component: () => import('../pages/mmppApprovePage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([APPROVE_EVALUACION_DIRECTOR])],
  },
  {
    path: 'add_report/:token',
    name: 'mmppAddReportPage',
    meta: { title: 'Bandeja de mmpp - DPVTMMP' },
    component: () => import('../pages/mmppAddInformePage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([INSERT_EVALUACION])],
  },
  {
    path: 'valoracion',
    name: 'mmppValoracionPage',
    meta: { title: 'Bandeja de mmpp - DPVTMMP' },
    component: () => import('../pages/mmppValoracionPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([INSERT_EVALUACION])],
  },
  {
    path: 'valoracion/files/:token',
    name: 'mmppValoracionFilesPage',
    meta: { title: 'Valoración de medidas de protección - DPVTMMP' },
    component: () => import('../pages/mmppAddValFilesPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([INSERT_EVALUACION])],
  },
  {
    path: 'verificacion/:token',
    name: 'mmppVerificacionAleatoriaPage',
    meta: { title: 'Valoración de medidas de protección - DPVTMMP' },
    component: () => import('../pages/mmppAleatorioPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([INSERT_EVALUACION])],
  },
  {
    path: 'seguimiento/conminacion/:token',
    name: 'mmppConminacionInformePage',
    meta: { title: 'Conminación de informe - DPVTMMP' },
    component: () => import('../pages/mmppConminacionInforme.vue'),
    beforeEnter: [() => hasPermissionMiddleware([INSERT_EVALUACION])],
  },
  {
    path: 'seguimiento/informes/:token',
    name: 'mmppSeguimientoInformePage',
    meta: { title: 'Seguimiento de informe - DPVTMMP' },
    component: () => import('../pages/mmppSeguimientoInforme.vue'),
    beforeEnter: [() => hasPermissionMiddleware([INSERT_EVALUACION])],
  },
  {
    path: 'seguimiento/emision_nota/:token',
    name: 'mmppEmisionNotaPage',
    meta: { title: 'Seguimiento de informe - DPVTMMP' },
    component: () => import('../pages/mmppEmisionNotaPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([INSERT_EVALUACION])],
  },
];

export default routes;
