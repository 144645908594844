<template>
  <div class="layout-footer py-2 px-3" :class="{ 'bg-custom': isGradient }">
    <div class="flex flex-row flex-wrap">
      <div class="flex-none align-items-center justify-content-center">
        <span class="font-italic" :class="{ 'text-custom-1': isGradient, 'text-custom': !isGradient }">
          Copyright © {{ year }} {{ institucion }}</span>
      </div>
      <div class="flex-grow-1 align-items-center justify-content-center"></div>
      <div class="flex-none align-items-center justify-content-center">
        <span class="font-italic" :class="{ 'text-custom-1': isGradient, 'text-custom': !isGradient }">
          {{ app }} | v{{ version }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { PROYECT_VERSION, INSTITUCION_NAME, PROYECT_NAME } from '../../../helpers/constants';

export default {
  name: 'AppFooter',
  props: {
    isGradient: {
      default: true,
    },
  },
  setup() {
    return {
      year: (new Date()).getFullYear(),
      institucion: INSTITUCION_NAME,
      version: PROYECT_VERSION,
      app: PROYECT_NAME,
    };
  },
};
</script>

<style lang="scss">
.bg-custom {
  background: var(--primary-dark-color);
  // background: linear-gradient(305deg, var(--primary-dark-color) 0%, var(--primary-color) 50%, var(--primary-light-color) 100%);
  // background: linear-gradient(305deg, var(--primary-dark-color) 0%, var(--primary-dark-color) 30%, var(--primary-light-color) 100%);
}

.text-custom {
  font-size: 0.8rem;
  color: var(--surface-500)
}

.text-custom-1 {
  font-size: 0.8rem;
  color: white
}
</style>