import { VIEW_LIST_AGENDAMIENTO } from '../../../constants/permissions.const';
import { hasPermissionMiddleware } from '../../../router/auth.guard';

const routes = [
  {
    path: '',
    name: 'agendamiento',
    redirect: { name: 'agendamientoPage' },
  },
  {
    path: 'list',
    name: 'agendamientoPage',
    meta: { title: 'Agendamiento - DPVTMMP' },
    component: () => import('../pages/AgendamientoPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_AGENDAMIENTO])],
  },
  {
    path: 'gesell/calendar',
    name: 'agendamientoGesselPage',
    meta: { title: 'Agendamiento - DPVTMMP' },
    component: () => import('../pages/AgendamientoGesselPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_AGENDAMIENTO])],
  },
  {
    path: 'gesell/list',
    name: 'gesellPage',
    meta: { title: 'Agendamiento - DPVTMMP' },
    component: () => import('../pages/GesselPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_AGENDAMIENTO])],
  },
];

export default routes;
