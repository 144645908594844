<template>
  <router-view />
</template>
<script>
import { mixinLanguage } from './hooks/useChangeLanguage';

export default {
  mixins: [mixinLanguage],
};
</script>
