import { usePrimeVue } from 'primevue/config';

// export default function useChangeLanguage() {
//   const primevue = usePrimeVue();

//   const changeToSpanish = () => {
//     primevue.config.locale.startsWith = 'Comienza con';
//     primevue.config.locale.contains = 'Contiene';
//     primevue.config.locale.notContains = 'No contiene';
//     primevue.config.locale.endWith = 'Termina con';
//     primevue.config.locale.equals = 'Iguales';
//     primevue.config.locale.notEquals = 'No iguales';
//     primevue.config.locale.noFilter = 'No filtros';
//     primevue.config.locale.lt = 'Menos que';
//     primevue.config.locale.lte = 'Menos que o igual a';
//     primevue.config.locale.gt = 'Mayor que';
//     primevue.config.locale.gte = 'Mayor que o igual a';
//     primevue.config.locale.dateIs = 'Fecha igual';
//     primevue.config.locale.dateIsNot = 'fecha no igual';
//     primevue.config.locale.dateBefore = 'antes de la fecha';
//     primevue.config.locale.dateAfter = 'despues de la fecha';
//     primevue.config.locale.clear = 'Limpiar';
//     primevue.config.locale.apply = 'Aplicar';
//     primevue.config.locale.matchAll = 'Coincidir con todos';
//     primevue.config.locale.matchAny = 'Coincidir con cualquiera';
//     primevue.config.locale.addRule = 'Agregar Regla';
//     primevue.config.locale.removeRule = 'Eliminar Regla';
//     primevue.config.locale.accept = 'Si';
//     primevue.config.locale.reject = 'No';
//     primevue.config.locale.choose = 'Elegir';
//     primevue.config.locale.upload = 'Subir';
//     primevue.config.locale.cancel = 'Cancelar';
//     primevue.config.locale.dayNames = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'];
//     primevue.config.locale.dayNamesShort = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
//     primevue.config.locale.dayNamesMin = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'];
//     primevue.config.locale.monthNames = [
//       'Enero',
//       'Febrero',
//       'Marzo',
//       'Abril',
//       'Mayo',
//       'Junio',
//       'Julio',
//       'Agosto',
//       'Septiembre',
//       'Octobre',
//       'Noviembre',
//       'Diciembre',
//     ];
//     primevue.config.locale.monthNamesShort = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
//     primevue.config.locale.today = 'hoy';
//     primevue.config.locale.weekHeader = 'Wk';
//     primevue.config.locale.firstDayOfWeek = 0;
//     primevue.config.locale.weak = 'Debil';
//     primevue.config.locale.medium = 'Medio';
//     primevue.config.locale.strong = 'Fuerte';
//     primevue.config.locale.passwordPrompt = 'Introduzca su contraseña';
//     primevue.config.locale.emptyFilterMessage = 'No se encontrarón resultados';
//     primevue.config.locale.emptyMessage = 'Lista vacia';
//   };

//   return {
//     changeToSpanish,
//   };
// }

export const mixinLanguage = {
  mounted() {
    this.$primevue.config.locale.startsWith = 'Comienza con';
    this.$primevue.config.locale.contains = 'Contiene';
    this.$primevue.config.locale.notContains = 'No contiene';
    this.$primevue.config.locale.endWith = 'Termina con';
    this.$primevue.config.locale.equals = 'Iguales';
    this.$primevue.config.locale.notEquals = 'No iguales';
    this.$primevue.config.locale.noFilter = 'No filtros';
    this.$primevue.config.locale.lt = 'Menos que';
    this.$primevue.config.locale.lte = 'Menos que o igual a';
    this.$primevue.config.locale.gt = 'Mayor que';
    this.$primevue.config.locale.gte = 'Mayor que o igual a';
    this.$primevue.config.locale.dateIs = 'Fecha igual';
    this.$primevue.config.locale.dateIsNot = 'fecha no igual';
    this.$primevue.config.locale.dateBefore = 'antes de la fecha';
    this.$primevue.config.locale.dateAfter = 'despues de la fecha';
    this.$primevue.config.locale.clear = 'Limpiar';
    this.$primevue.config.locale.apply = 'Aplicar';
    this.$primevue.config.locale.matchAll = 'Coincidir con todos';
    this.$primevue.config.locale.matchAny = 'Coincidir con cualquiera';
    this.$primevue.config.locale.addRule = 'Agregar Regla';
    this.$primevue.config.locale.removeRule = 'Eliminar Regla';
    this.$primevue.config.locale.accept = 'Si';
    this.$primevue.config.locale.reject = 'No';
    this.$primevue.config.locale.choose = 'Elegir';
    this.$primevue.config.locale.upload = 'Subir';
    this.$primevue.config.locale.cancel = 'Cancelar';
    this.$primevue.config.locale.dayNames = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'];
    this.$primevue.config.locale.dayNamesShort = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];
    this.$primevue.config.locale.dayNamesMin = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'];
    this.$primevue.config.locale.monthNames = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octobre',
      'Noviembre',
      'Diciembre',
    ];
    this.$primevue.config.locale.monthNamesShort = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    this.$primevue.config.locale.today = 'Hoy';
    this.$primevue.config.locale.weekHeader = 'Wk';
    this.$primevue.config.locale.firstDayOfWeek = 0;
    this.$primevue.config.locale.weak = 'Debil';
    this.$primevue.config.locale.medium = 'Medio';
    this.$primevue.config.locale.strong = 'Fuerte';
    this.$primevue.config.locale.passwordPrompt = 'Introduzca su contraseña';
    this.$primevue.config.locale.emptyFilterMessage = 'No se encontrarón resultados';
    this.$primevue.config.locale.emptyMessage = 'Lista vacia';
  },
};
