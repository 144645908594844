const routes = [
  {
    path: '',
    name: 'fiscal',
    redirect: { name: 'reqFiscalPage' },
  },
  {
    path: 'req_fiscal/:token',
    name: 'reqFiscalPage',
    meta: { title: 'Bandeja de requerimientos - DPVTMMP ' },
    component: () => import('../pages/ReqFiscalPage.vue'),
  },
  {
    path: 'req_fiscal/create/:token',
    name: 'createReqFiscalPage',
    meta: { title: 'Requerimientos - DPVTMMP' },
    component: () => import('../pages/CreateReqFiscal.vue'),
  },
  {
    path: 'req_fiscal/sign/:token',
    name: 'signReqFiscalPage',
    meta: { title: 'Requerimientos - DPVTMMP' },
    component: () => import('../pages/SignReqFiscal.vue'),
  },
  {
    path: 'req_fiscal/requerimiento/ver',
    name: 'reqFiscalRequerimientoPreviewPage',
    meta: { title: 'Requerimientos - DPVTMMP' },
    component: () => import('../pages/PreviewReqFiscal.vue'),
  },
  {
    path: 'req_fiscal/asistencia/list/:casoId',
    name: 'asistenciaJLPage',
    meta: { title: 'Requerimientos de asistencia' },
    component: () => import('../pages/AsistenciaReqJLPage.vue'),
  },
  {
    path: 'req_fiscal/asistencia/ver',
    name: 'reqFiscalAsistenciaPreviewPage',
    meta: { title: 'Requerimientos de asistencia' },
    component: () => import('../pages/PreviewAsistPage.vue'),
  },
  {
    path: 'req_fiscal/asistencia/requerimiento/:id?',
    name: 'reqFiscalAsistenciaCreatePage',
    meta: { title: 'Requerimientos de asistencia' },
    component: () => import('../pages/AsistenciaCreateReq.vue'),
  },
  //USUARIO ROUTES
  {
    path: 'login_usuario',
    name: 'loginUsuario',
    meta: { title: 'Login - MMPP' },
    component: () => import('../pages/usuarioPages/LoginUsuario.vue'),
  },
  {
    path: 'bandeja_usuario',
    name: 'bandejaUsuarioPage',
    meta: { title: 'Bandeja - MMPP' },
    component: () => import('../pages/usuarioPages/BandejaUsuario.vue'),
  },
];

export default routes;
