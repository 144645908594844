export const locale = {
  startsWith: 'Comienza con',
  contains: 'Contiene',
  notContains: 'No contiene',
  endsWith: 'Termina con',
  equals: 'Iguales',
  notEquals: 'No iguales',
  noFilter: 'No filtros',
  lt: 'Menos que',
  lte: 'Menos que o igual a',
  gt: 'Mayor que',
  gte: 'Mayor que o igual a',
  dateIs: 'Fecha igual',
  dateIsNot: 'fecha no igual',
  dateBefore: 'antes de la fecha',
  dateAfter: 'despues de la fecha',
  clear: 'Limpiar',
  apply: 'Aplicar',
  matchAll: 'Coincidir con todos',
  matchAny: 'Coincidir con cualquiera',
  addRule: 'Agregar Regla',
  removeRule: 'Eliminar Regla',
  accept: 'Si',
  reject: 'No',
  choose: 'Elegir',
  upload: 'Subir',
  cancel: 'Cancelar',
  dayNames: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'],
  dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
  dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
  monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octobre', 'Noviembre', 'Diciembre'],
  monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  today: 'hoy',
  weekHeader: 'Wk',
  firstDayOfWeek: 0,
  dateFormat: 'dd/mm/yy',
  weak: 'Debil',
  medium: 'Medio',
  strong: 'Fuerte',
  passwordPrompt: 'Introduzca su contraseña',
  emptyFilterMessage: 'No se encontrarón resultados',
  emptyMessage: 'Lista vacia',
};
