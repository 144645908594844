import { hasPermission, hasFiscalRole } from '../helpers/permissions.helper';
import { onLogoutService } from '../modules/auth/api/auth.api';

export const isAuthenticatedGuard = async (to, from, next) => {
  const isFiscal = hasFiscalRole();
  const token = localStorage.getItem('token');
  if (isFiscal) {
    await logoutFiscal();
    next({ name: 'reqFiscalFinPage' });
    return;
  }
  if (token) {
    next();
  } else {
    //Elimina la info del usuario del localstorage
    localStorage.removeItem('app_info');
    localStorage.removeItem('token');
    localStorage.removeItem('binnacleId');
    next({ name: 'loginPage' });
  }
};

export const isPublic = (to, from, next) => {
  const token = localStorage.getItem('token');
  if (token) {
    next({ name: 'dashboardPage' });
  } else {
    localStorage.removeItem('app_info');
    localStorage.removeItem('token');
    localStorage.removeItem('binnacleId');
    next();
  }
};

export const isPublicFiscal = async (to, from, next) => {
  await logoutFiscal();
  next();
};

const logoutFiscal = async () => {
  const token = localStorage.getItem('token');
  localStorage.removeItem('app_info');
  localStorage.removeItem('token');
  localStorage.removeItem('binnacleId');
  try {
    await onLogoutService(token);
  } catch (error) {
    // Do nothing
  }
};

export const hasPermissionMiddleware = (permissions) => {
  return function (req, res, next) {
    if (hasPermission([...permissions])) {
      next();
    } else {
      next({ name: 'dashboardPage' });
    }
  };
};
