import { INSERT_EVALUACION, VIEW_EVALUACION, VIEW_LIST_EVALUACIONES } from '../../../constants/permissions.const';
import { hasPermissionMiddleware } from '../../../router/auth.guard';

const routes = [
  {
    path: '',
    name: 'bandejaUpavt',
    redirect: { name: 'bandejaUpavtPage' },
  },
  {
    path: 'list',
    name: 'bandejaUpavtPage',
    meta: { title: 'Bandeja de solicitudes de trabajo - DPVTMMP' },
    component: () => import('../pages/BandejaUpavt.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_EVALUACIONES])],
  },
  {
    path: 'preview',
    name: 'previewUpavtPage',
    meta: { title: 'Bandeja de solicitudes de trabajo - DPVTMMP' },
    component: () => import('../pages/PreviewUpavtPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_EVALUACION])],
  },
  {
    path: 'approve/:token',
    name: 'approveUpavtPage',
    meta: { title: 'Bandeja de solicitudes de trabajo - DPVTMMP' },
    component: () => import('../pages/EvalSolicitudPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([INSERT_EVALUACION])],
  },
  {
    path: 'add_file/:token',
    name: 'addFileUpavtPage',
    meta: { title: 'Bandeja de solicitudes de trabajo - DPVTMMP' },
    component: () => import('../pages/AddActividadFiles.vue'),
    beforeEnter: [() => hasPermissionMiddleware([INSERT_EVALUACION])],
  },
  {
    path: 'psicologia/emitir_informe/:token',
    name: 'addPsiFileUpavtPage',
    meta: { title: 'Bandeja de solicitudes de trabajo - DPVTMMP' },
    component: () => import('../pages/AddActPsicologiaFiles.vue'),
    beforeEnter: [() => hasPermissionMiddleware([INSERT_EVALUACION])],
  },
  {
    path: 'social/emitir_informe/:token',
    name: 'addSocFileUpavtPage',
    meta: { title: 'Bandeja de solicitudes de trabajo - DPVTMMP' },
    component: () => import('../pages/AddActSocialFiles.vue'),
    beforeEnter: [() => hasPermissionMiddleware([INSERT_EVALUACION])],
  },
];

export default routes;
