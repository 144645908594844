import { VIEW_LIST_CATALOGOS, VIEW_LIST_PLANTILLAS } from '../../../constants/permissions.const';
import { hasPermissionMiddleware } from '../../../router/auth.guard';

const routes = [
  {
    path: '',
    name: 'catalogos',
    redirect: { name: 'tipoSolicitudPage' },
  },
  {
    path: 'etapas',
    name: 'etapasPage',
    meta: { title: 'Catalogos - Etapas - DPVTMMP' },
    component: () => import('../pages/EtapasPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'sub_etapas',
    name: 'subEtapasPage',
    meta: { title: 'Catalogos - Sub etapas - DPVTMMP' },
    component: () => import('../pages/SubEtapasPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'eventos',
    name: 'eventosPage',
    meta: { title: 'Catalogos - Eventos - DPVTMMP' },
    component: () => import('../pages/EventosPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'situaciones_peligro',
    name: 'situacionPeligro',
    meta: { title: 'Catalogos - Situaciones de peligro - DPVTMMP' },
    component: () => import('../pages/SituacionPeligroPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'areas',
    name: 'areasPage',
    meta: { title: 'Catalogos - Areas de atención - DPVTMMP' },
    component: () => import('../pages/AreasAtencionPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'actividades_gesell',
    name: 'actividadesGessel',
    meta: { title: 'Catalogos - Actividades de cámara gesell - DPVTMMP' },
    component: () => import('../pages/ActGesselPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'actividades_instituciones',
    name: 'actividadesInstPage',
    meta: { title: 'Catalogos - Actividades de instituciones - DPVTMMP' },
    component: () => import('../pages/ActividadesInstitucionPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'actividades_asistencia',
    name: 'actividadesAsistPage',
    meta: { title: 'Catalogos - Actividades de asistencia - DPVTMMP' },
    component: () => import('../pages/ActAsistenciasPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'actividades_respuesta',
    name: 'actividadesAsistRespPage',
    meta: { title: 'Catalogos - Actividades de asistencia - DPVTMMP' },
    component: () => import('../pages/ActAsistRespuestasPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'actividades_psicologicas',
    name: 'psicologicasPage',
    meta: { title: 'Catalogos - Actividades Psicologicas - DPVTMMP' },
    component: () => import('../pages/ActPsicologicasPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'actividades_sociales',
    name: 'socialesPage',
    meta: { title: 'Catalogos - Actividades Sociales - DPVTMMP' },
    component: () => import('../pages/ActSocialesPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'tipo_contactos',
    name: 'tipoContactoPage',
    meta: { title: 'Catalogos - Tipos de contactos - DPVTMMP' },
    component: () => import('../pages/TipoContactoPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'tipo_documentos',
    name: 'tipoDocumentoPage',
    meta: { title: 'Catalogos - Tipos de documentos - DPVTMMP' },
    component: () => import('../pages/TipoDocumentoPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'medidas_proteccion',
    name: 'mmppCatalogoPage',
    meta: { title: 'Catalogos - Medidas de protección - DPVTMMP' },
    component: () => import('../pages/MedidasProteccionPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'plantillas',
    name: 'plantillasPage',
    meta: { title: 'Catalogos - Plantillas - DPVTMMP' },
    component: () => import('../pages/PlantillasPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_PLANTILLAS])],
  },
  {
    path: 'tipo_victima',
    name: 'tipoVictimaPage',
    meta: { title: 'Catalogos - Tipos de victimas - DPVTMMP' },
    component: () => import('../pages/TipoVictimaPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'grupo_vulnerable',
    name: 'grupoVulnerablePage',
    meta: { title: 'Catalogos - Tipos de documentos - DPVTMMP' },
    component: () => import('../pages/GrupoVulnerablePage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'generos',
    name: 'generoPage',
    meta: { title: 'Catalogos - Generos - DPVTMMP' },
    component: () => import('../pages/GenerosPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'tipo_agenda',
    name: 'agendaPage',
    meta: { title: 'Catalogos - Tipo de agenda - DPVTMMP' },
    component: () => import('../pages/CatAgendamientoPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'formularios',
    name: 'formularioPage',
    meta: { title: 'Catalogos - Formularios - DPVTMMP' },
    component: () => import('../pages/FormulariosPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'incidencias',
    name: 'incidenciaPage',
    meta: { title: 'Incidencias - Formularios - DPVTMMP' },
    component: () => import('../pages/IncidenciasPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'motivos',
    name: 'motivoPage',
    meta: { title: 'Motivos de solicitud de plazo - DPVTMMP' },
    component: () => import('../pages/MotivoPlazoPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
  {
    path: 'gesell_ubicaciones',
    name: 'gesellUbicacionesPage',
    meta: { title: 'Ubicaciones de cámara gesell - DPVTMMP' },
    component: () => import('../pages/GesellUbicacionesPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_CATALOGOS])],
  },
];

export default routes;
