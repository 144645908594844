const routes = {
  name: 'auth',
  component: () => import('@/shared/layouts/empty.layout.vue'),
  children: [
    {
      path: '',
      name: 'authPage',
      redirect: { name: 'loginPage' },
    },
    {
      path: '/login',
      name: 'loginPage',
      meta: { title: 'INICIAR SESIÓN - DPVTMMP' },
      component: () => import('../pages/LoginPage.vue'),
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      meta: { title: 'RECUPERAR CONTRASEÑA - DPVTMMP' },
      component: () => import('../pages/ForgotPasswordPage.vue'),
    },
  ],
};

export default routes;
