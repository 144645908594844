import { defineAsyncComponent } from 'vue';

const router = {
  name: 'errorPages',
  component: defineAsyncComponent(() => import(/* webpackChunkName: "DefaultLayout" */ '@/shared/layouts/empty.layout.vue')),
  children: [
    {
      path: 'access',
      name: 'not-access',
      meta: { title: 'Acceso denegado' },
      component: defineAsyncComponent(() => import(/* webpackChunkName: "Access" */ '../views/access.view.vue')),
    },
    {
      path: 'error',
      name: 'error',
      meta: { title: 'Error' },
      component: defineAsyncComponent(() => import(/* webpackChunkName: "Access" */ '../views/error.view.vue')),
    },
    {
      path: '404',
      name: 'not-found',
      meta: { title: 'No encontrado' },
      component: defineAsyncComponent(() => import(/* webpackChunkName: "NotFound" */ '../views/not-found.view.vue')),
    },
  ],
};

export default router;
