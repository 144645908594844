<template>
  <div class="loading">
    <div class="box">
      <div class="b b1"></div>
      <div class="b b2"></div>
      <div class="b b3"></div>
      <div class="b b4"></div>
      <span class="txt"> Cargando</span>
    </div>
  </div>
</template>

<style lang="scss" >
.loading {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0000005e;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
}
.loading {
  .box {
    width: 20rem;
    height: 20rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }
  .box .b {
    border-radius: 50%;
    border-left: 4px solid;
    border-right: 4px solid;
    border-top: 4px solid transparent !important;
    border-bottom: 4px solid transparent !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ro 2s infinite;
  }

  .box .b1 {
    border-color: #4a69bd;
    width: 11.2rem;
    height: 11.2rem;
  }

  .box .b2 {
    border-color: #f6b93b;
    width: 10.2rem;
    height: 10.2rem;
    animation-delay: 0.2s;
  }

  .box .b3 {
    border-color: #2ecc71;
    width: 9.2rem;
    height: 9.2rem;
    animation-delay: 0.4s;
  }

  .box .b4 {
    border-color: #21aabb;
    width: 8rem;
    height: 8rem;
    animation-delay: 0.6s;
  }

  .txt {
    text-align: center;
    width: 100%;
    display: block;
    margin-top: 47%;
    font-size: 1rem;
    // text-shadow: 1px 1px 3px #1f9eaaa6;
    // color: black;
    font-weight: bold;
    color: white;
  }

  @keyframes ro {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    50% {
      transform: translate(-50%, -50%) rotate(-180deg);
    }

    100% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }
}
</style>