import { INSERT_REQUERIMIENTO, INSERT_SOLICITUD, VIEW_LIST_REQUERIMIENTOS, VIEW_REQUERIMIENTO } from '../../../constants/permissions.const';
import { hasPermissionMiddleware } from '../../../router/auth.guard';

const routes = [
  {
    path: '',
    name: 'requerimientos',
    redirect: { name: 'requerimientosPage' },
  },
  {
    path: '/requerimientos',
    name: 'requerimientosPage',
    meta: { title: 'Bandeja de requerimientos - DPVTMMP' },
    component: () => import('../pages/RequerimientosPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_REQUERIMIENTOS])],
  },
  {
    path: '/requerimientos/create',
    name: 'createRequerimientosPage',
    meta: { title: 'Requerimientos - DPVTMMP' },
    component: () => import('../pages/CreateRequerimientoPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([INSERT_REQUERIMIENTO])],
  },
  {
    path: '/requerimientos/add_document/:token',
    name: 'documentsRequerimientosPage',
    meta: { title: 'Requerimientos - DPVTMMP' },
    component: () => import('../pages/AddRequerimientoPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([INSERT_REQUERIMIENTO])],
  },
  {
    path: '/requerimientos/ver',
    name: 'previewRequerimientoPage',
    meta: { title: 'Requerimientos - DPVTMMP' },
    component: () => import('../pages/PreviewRequerimientoPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_REQUERIMIENTO])],
  },
  {
    path: '/requerimientos/preview',
    name: 'approveRequerimientoPage',
    meta: { title: 'Requerimientos - DPVTMMP' },
    component: () => import('../pages/ApproveRequerimientoPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([INSERT_SOLICITUD])],
  },
  {
    path: '/requerimientos/sign/:token',
    name: 'addDocsApproveRequerimientoPage',
    meta: { title: 'Requerimientos - DPVTMMP' },
    component: () => import('../pages/AddDocsApproveRequerimientoPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([INSERT_SOLICITUD])],
  },
];

export default routes;
