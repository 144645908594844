import base64 from 'base-64';
import utf8 from 'utf8';

export const encode = (value) => {
  try {
    const bytes = utf8.encode(value);
    return base64.encode(bytes);
  } catch (error) {
    return null;
  }
};
export const decode = (value) => {
  try {
    const bytes = base64.decode(value);
    return utf8.decode(bytes);
  } catch (error) {
    return null;
  }
};

export const encodeObj = (value) => {
  try {
    return encode(JSON.stringify(value));
  } catch (error) {
    return null;
  }
};

export const decodeData = (value) => {
  try {
    let item = JSON.parse(decode(value));
    return item;
  } catch (error) {
    return null;
  }
};
