import axios from 'axios';

export const webpushInstance = axios.create({
  baseURL: `${import.meta.env.VITE_VICTIMAS_API}`,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

webpushInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const postWebPushApi = async (values) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio para conectarse con las notificaciones web push .',
    response: {},
    status: 422,
  };

  await webpushInstance
    .post(`/v1/api/web/subscription`, values)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};
