import { RRHH_API_URL } from './constants';
import { decode } from './encode.helper';

export const genUrlFoto = (infoObject) => {
  const fileId = JSON.parse(decode(infoObject))?.user.fotoRuta;
  const rrhhUrl = RRHH_API_URL || '';
  if (fileId && rrhhUrl) {
    return `${rrhhUrl}/api/personas/fotosegip/${fileId}`;
  }
  return `/assets/img/default.svg`;
};

export const onImageError = (ev, imageRoute = '/assets/img/default.svg') => {
  ev.target.onerror = null;
  ev.target.src = imageRoute;
};
