<template>
  <Sidebar class="pdf-custom-sidebar z-index-1004" position="full" :dismissable="false" v-model:visible="displayModal" :baseZIndex="1001" width="100%">
    <template #header>
      <div class="flex justify-content-between align-items-center">
        <p class="font-semibold uppercase px-2 mb-0">Previsualización de documento</p>
        <Button
          v-if="signatures"
          class="mr-1"
          icon="fa fa-file-signature"
          label="Aprobaciones del documento"
          v-tooltip.left="'Aprobaciones del documento'"
          @click="onWatchSignatures()"
        >
        </Button>
      </div>
    </template>

    <div class="grid grid-nogutter h-full w-full">
      <iframe :src="modelValue" frameborder="0" width="100%" height="100%"> </iframe>
    </div>
  </Sidebar>
  <DialogSignatureList ref="formModal" :signatures="signatures" />
</template>

<script>
export default {
  name: 'DialogPreviewPDF',
};
</script>
<script setup>
import { ref } from 'vue';
import useGenericDialog from '../../../shared/hooks/useGenericDialog';
import DialogSignatureList from './DialogSignatureList.vue';

const emits = defineEmits(['update:modalValue']);
const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  signatures: {
    required: false,
  },
});

const { displayModal, openDialog, closeDialog } = useGenericDialog();
const formModal = ref();

const onClose = () => {
  emits('update:modalValue', '');
  closeDialog();
};

const onWatchSignatures = () => {
  if (Array.isArray(props.signatures)) {
    formModal.value.openDialog();
  }
};

defineExpose({
  openDialog,
  onClose,
});
</script>

<style lang="scss">
.pdf-custom-sidebar {
  & .p-sidebar-header {
    padding: 1rem 0.5rem 0.75rem 0.5rem !important;
  }
  & .p-sidebar-content {
    overflow: hidden;
    padding: 0 !important;
  }
}
.z-index-1004 {
  z-index: 1004;
}

.z-index-1005 {
  z-index: 1005;
}
</style>
