import { VIEW_LIST_ESTRUCTURA } from '../../../constants/permissions.const';
import { hasPermissionMiddleware } from '../../../router/auth.guard';

const routes = [
  {
    path: '',
    name: 'estructura',
    redirect: { name: 'institucionPage' },
  },
  {
    path: 'instituciones',
    name: 'institucionPage',
    meta: { title: 'Bandeja de instituciones - DPVTMMP' },
    component: () => import('../pages/InstitucionPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_ESTRUCTURA])],
  },
  {
    path: 'instituciones/convenio/:id',
    name: 'conveniosPage',
    meta: { title: 'Convenios de la institución - DPVTMMP' },
    component: () => import('../pages/ConvenioInstPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_ESTRUCTURA])],
  },
  {
    path: 'entidades',
    name: 'entidadesPage',
    meta: { title: 'Bandeja de entidades - DPVTMMP' },
    component: () => import('../pages/EntidadesPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_ESTRUCTURA])],
  },
  {
    path: 'oficinas',
    name: 'oficinasPage',
    meta: { title: 'Bandeja de oficinas - DPVTMMP' },
    component: () => import('../pages/OficinaPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_ESTRUCTURA])],
  },
  {
    path: 'divisiones',
    name: 'divisionesPage',
    meta: { title: 'Bandeja de divisiones - DPVTMMP' },
    component: () => import('../pages/DivisionesPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_ESTRUCTURA])],
  },
  {
    path: 'unidades',
    name: 'unidadesPage',
    meta: { title: 'Bandeja de unidades organizacionales - DPVTMMP' },
    component: () => import('../pages/UnidadesPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_ESTRUCTURA])],
  },
];

export default routes;
