//#region MODULO GENERAL
export const VER_BOLIVIA = 'VICT_VIEW_BOLIVIA';
export const VER_DEPARTAMENTO = 'VICT_VIEW_DEPARTAMENTO';
export const VER_FUNCIONARIO = 'VICT_VIEW_FUNCIONARIO';
export const DIRECTOR_PERM = 'DIRECTOR';
export const FUNC_PERM = 'FUNCIONARIO';

//#region MODULO SISTEMA
export const INSERT_SISTEMA = 'INSERT_SISTEMA';
export const EDIT_SISTEMA = 'EDIT_SISTEMA';
export const DELETE_SISTEMA = 'DELETE_SISTEMA';
//#endregion

//#region MODULO CATALOGO
export const VIEW_CATALOGO = 'VIEW_CATALOGO';
export const VIEW_LIST_CATALOGOS = 'VIEW_LIST_CATALOGOS';
export const INSERT_CATALOGO = 'INSERT_CATALOGO';
export const EDIT_CATALOGO = 'EDIT_CATALOGO';
export const DELETE_CATALOGO = 'DELETE_CATALOGO';
//#endregion

//#region Permisos Plantillas
export const VIEW_PLANTILLA = 'VIEW_PLANTILLA';
export const VIEW_LIST_PLANTILLAS = 'VIEW_LIST_PLANTILLAS';
export const INSERT_PLANTILLA = 'INSERT_PLANTILLA';
export const EDIT_PLANTILLA = 'EDIT_PLANTILLA';
export const DELETE_PLANTILLA = 'DELETE_PLANTILLA';
//#endregion

//#region Permisos Estructura Institucional
export const VIEW_ESTRUCTURA = 'VIEW_ESTRUCTURA';
export const VIEW_LIST_ESTRUCTURA = 'VIEW_LIST_ESTRUCTURA';
export const INSERT_ESTRUCTURA = 'INSERT_ESTRUCTURA';
export const EDIT_ESTRUCTURA = 'EDIT_ESTRUCTURA';
export const DELETE_ESTRUCTURA = 'DELETE_ESTRUCTURA';
//#endregion

//#region Permisos Funcionarios
export const VIEW_FUNCIONARIO = 'VIEW_FUNCIONARIO';
export const VIEW_LIST_FUNCIONARIOS = 'VIEW_LIST_FUNCIONARIOS';
export const INSERT_FUNCIONARIO = 'INSERT_FUNCIONARIO';
export const ASSIGN_FUNCIONARIOS = 'ASSIGN_FUNCIONARIOS';
export const EDIT_FUNCIONARIO = 'EDIT_FUNCIONARIO';
export const DELETE_FUNCIONARIO = 'DELETE_FUNCIONARIO';
//#endregion

//#region Permisos Personas
export const VIEW_PERSONA = 'VIEW_PERSONA';
export const VIEW_LIST_PERSONAS = 'VIEW_LIST_PERSONAS';
export const INSERT_PERSONA = 'INSERT_PERSONA';
export const EDIT_PERSONA = 'EDIT_PERSONA';
//#endregion

//#region Permisos Requerimientos
export const VIEW_REQUERIMIENTO = 'VIEW_REQUERIMIENTO';
export const VIEW_LIST_REQUERIMIENTOS = 'VIEW_LIST_REQUERIMIENTOS';
export const INSERT_REQUERIMIENTO = 'INSERT_REQUERIMIENTO';
export const EDIT_REQUERIMIENTO = 'EDIT_REQUERIMIENTO';
//#endregion

//#region Permisos Documentos
export const INSERT_DOCUMENT = 'INSERT_DOCUMENT';
export const EDIT_DOCUMENT = 'EDIT_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
//#endregion

//#region  Permisos Solicitud De Trabajo
export const VIEW_SOLICITUD = 'VIEW_SOLICITUD';
export const VIEW_LIST_SOLICITUDES = 'VIEW_LIST_SOLICITUDES';
export const INSERT_SOLICITUD = 'INSERT_SOLICITUD';
export const APPROVE_SOLICITUD_DIRECTOR = 'APPROVE_SOLICITUD_DIRECTOR';
export const EDIT_SOLICITUD_FUNCIONARIO = 'EDIT_SOLICITUD_FUNCIONARIO';
export const EDIT_SOLICITUD = 'EDIT_SOLICITUD';
export const INSERT_SOLICITUD_AMPLIACION = 'INSERT_SOLICITUD_AMPLIACION';
//#endregion

//#region   Permisos Medidas de Protección
export const VIEW_MMPP = 'VIEW_MMPP';
// Inserta las medidas de protección
export const APPROVE_EVALUACION_DIRECTOR = 'APPROVE_EVALUACION_DIRECTOR';
export const VIEW_HOJA_RUTA_MMPP = 'VIEW_HOJA_RUTA_MMPP';
export const DELETE_HOJA_RUTA_MMPP = 'DELETE_HOJA_RUTA_MMPP';
export const VIEW_LIST_MMPPS = 'VIEW_LIST_MMPPS';
export const VALORATION_MMPP_DIRECTOR = 'VALORATION_MMPP_DIRECTOR';
export const EDIT_MMPP = 'EDIT_MMPP';
export const VIEW_MMPP_PANEL_APROBACION = 'VIEW_MMPP_PANEL_APROBACION';
//#endregion

//#region Permisos Evaluación Psicológica y Social
export const VIEW_EVALUACION = 'VIEW_EVALUACION';
export const VIEW_LIST_EVALUACIONES = 'VIEW_LIST_EVALUACIONES';
export const INSERT_EVALUACION = 'INSERT_EVALUACION';
export const EDIT_EVALUACION = 'EDIT_EVALUACION';
//#endregion

//#region Permisos agendamiento

export const VIEW_AGENDAMIENTO = 'VIEW_AGENDAMIENTO';
export const VIEW_LIST_AGENDAMIENTO = 'VIEW_LIST_AGENDAMIENTO';
export const VIEW_LIST_AGENDAMIENTO_GESELL = 'VIEW_LIST_AGENDAMIENTO_GESELL';
export const INSERT_AGENDAMIENTO = 'INSERT_AGENDAMIENTO';
export const EDIT_AGENDAMIENTO = 'EDIT_AGENDAMIENTO';
export const DELETE_AGENDAMIENTO = 'DELETE_AGENDAMIENTO';

//#endregion

// #region Permisos asistencia
export const VIEW_ASISTENCIA = 'VIEW_ASISTENCIA';
export const VIEW_LIST_ASISTENCIAS = 'VIEW_LIST_ASISTENCIAS';
export const VIEW_LIST_ASISTENCIAS_FUNCIONARIOS = 'VIEW_LIST_ASISTENCIAS_FUNCIONARIO';
export const INSERT_ASISTENCIA = 'INSERT_ASISTENCIA';
export const EDIT_ASISTENCIA = 'EDIT_ASISTENCIA';
export const EDIT_ASISTENCIA_FUNCIONARIO = 'EDIT_ASISTENCIA_FUNCIONARIO';
export const CONMINACION_ASISTENCIA = 'CONMINACION_ASISTENCIA';
export const VIEW_LIST_REPROGRAMACIONES = 'VIEW_LIST_REPROGRAMACIONES';
//#endregion

// #region Permisos mmpp externos
export const VIEW_MMPP_EXTERNO = 'VIEW_MMPP_EXTERNO';
export const VIEW_MMPP_LIST_EXTERNO = 'VIEW_MMPP_LIST_EXTERNO';
export const EDIT_MMPP_EXTERNO = 'EDIT_MMPP_EXTERNO';
//#endregion

// #region Permisos mmpp externos
export const DOWNLOAD_REPORTES_PDF = 'DOWNLOAD_REPORTES_PDF';
export const DOWNLOAD_REPORTES_EXCEL = 'DOWNLOAD_REPORTES_EXCEL';
export const VIEW_DASHBOARD = 'VIEW_DASHBOARD_ASISTENCIAS';
export const VIEW_DASHBOARD_ASISTENCIA_FUNC = 'VIEW_DASHBOARD_ASISTENCIAS_FUNCIONARIO';
export const VIEW_DASHBOARD_MMPP = 'VIEW_DASHBOARD_MMPP';
export const VIEW_DASHBOARD_GESSEL = 'VIEW_DASHBOARD_GESELL';
//#endregion
