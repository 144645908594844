import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '../modules/auth/store/authStore';
import { onMounted, ref } from 'vue';
import { Swal, Toast } from '/src/shared/components/core/sweetAlert2';
import { CIUDADANIA_DIGITAL_URL } from '../helpers/constants';

export default function useCiudadaniaHook() {
  const route = useRoute();
  const router = useRouter();
  const user = useAuthStore();
  const userInfo = ref();

  onMounted(() => {
    userInfo.value = user.getUser;
    setTimeout(() => {
      checkEvents();
    }, 500);
  });

  const checkEvents = () => {
    // Check approbation file
    if (route.query.approbation) {
      const approbation = route.query.approbation;
      const approbationResp = JSON.parse(decodeURI(approbation));
      checkApprobation(approbationResp);
    }

    // Check autentication response
    if (route.query.agetic) {
      const agetic = route.query.agetic;
      const authResp = JSON.parse(decodeURI(agetic));
      checkAuthAgetic(authResp);
    }
  };

  const checkApprobation = async (respApprobation) => {
    if (respApprobation.error) {
      if (respApprobation.response?.estadoProceso) {
        Toast.fire({
          icon: 'error',
          title: respApprobation.response?.estadoProceso,
        });
      } else {
        Toast.fire({
          icon: 'error',
          title: respApprobation.message,
        });
      }
      if (respApprobation.response?.estadoProceso?.includes('Token Inválido')) {
        const result = await Swal.fire({
          title: '¿Desea renovar su sesión en ciudadanía digital?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
        });
        if (result.isConfirmed) {
          redirectCD();
        }
      }
    } else {
      Toast.fire({
        icon: 'success',
        title: 'Se aprobó el documento exitosamente.',
      });
    }
    router.push({
      name: route.name,
      params: { ...route.params },
      query: { ...route.query, approbation: undefined },
    });
  };

  const checkAuthAgetic = async (authData) => {
    if (!authData.error) {
      if (authData.response.binnacleId) {
        if (userInfo.value.ci === authData.response.ciudadanoDigital['documento_identidad']['numero_documento']) {
          user.setBinnacleId(authData.response.binnacleId);
          Toast.fire({
            icon: 'success',
            title: 'Su sesión en Ciudadanía Digital se a renovado exitosamente.',
          });
        } else {
          Toast.fire({
            icon: 'error',
            title: 'Identifiquese en Ciudadanía Digital con el mismo usuario que en el sistema.',
          });
        }
        router.push({
          name: route.name,
          params: { ...route.params },
          query: { ...route.query, agetic: undefined },
        });
      }
    } else {
      Toast.fire({
        icon: 'error',
        title: authData.response?.message,
      });
    }
  };

  const redirectCD = (finalUrlRedirect = false) => {
    const appCDigitalUrl = `${CIUDADANIA_DIGITAL_URL}`;
    const actualUrl = finalUrlRedirect ? finalUrlRedirect : `${window.location.origin}${window.location.pathname}`;
    const urlRedirect = `${appCDigitalUrl}/?urlRedirectLogin=${encodeURIComponent(actualUrl)}`;
    window.location.href = urlRedirect;
  };

  const redirectApprobationDocument = async (getDocumentUrl, notificationAprobationUrl, finalUrlRedirect = false) => {
    const binnacleId = user.getBinnacleId;
    if (!binnacleId) {
      const result = await Swal.fire({
        title: '¿Desea iniciar sesión en ciudadanía digital?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      });
      if (result.isConfirmed) {
        redirectCD(finalUrlRedirect);
      }
    } else {
      const actualUrl = finalUrlRedirect ? finalUrlRedirect : `${window.location.origin}${window.location.pathname}`;
      const appCDigitalUrl = CIUDADANIA_DIGITAL_URL;
      const urlRedirect = `${appCDigitalUrl}/?urlRedirectClient=${encodeURI(actualUrl)}&urlServiceDocument=${encodeURI(
        getDocumentUrl
      )}&urlNotificationDocument=${encodeURI(notificationAprobationUrl)}&binnacleId=${binnacleId}`;
      window.location.href = urlRedirect;
    }
  };

  const handleSignFile = async (idFile, finalUrlRedirect) => {
    let redirectUrl = `${window.location.origin}${window.location.pathname}`;
    redirectApprobationDocument(
      `${import.meta.env.VITE_VICTIMAS_API}v1/api/search/file/${localStorage.getItem('token')}/${idFile}`,
      `${import.meta.env.VITE_VICTIMAS_API}v1/api/approve/file/${localStorage.getItem('token')}/${idFile}`,
      finalUrlRedirect ? finalUrlRedirect : redirectUrl
    );
  };

  return {
    redirectCD,
    redirectApprobationDocument,
    handleSignFile,
  };
}
