<template>
  <div
    class="menu-wrapper"
    :class="{ 'layout-sidebar-active': sidebarActive }"
    @click="onMenuClick"
    @mouseover="onSidebarMouseOver"
    @mouseleave="onSidebarMouseLeave"
  >
    <div class="sidebar-logo px-3">
      <router-link to="/">
        <img class="logo-inst" src="/assets/img/logo_bol.png" alt="logo institución" />
      </router-link>
      <span v-if="sidebarActive" style="font-size: 0.9rem; font-weight: bold; width: 9rem; text-align: center">{{ app }}</span>
      <a v-if="sidebarActive" @click="onToggleMenu($event)" class="cursor-pointer font-semibold">
        <span v-if="!sidebarStatic" class="pin fa-regular fa-circle"></span>
        <span v-else class="pin fa fa-circle-dot"></span>
      </a>
    </div>
    <div class="layout-menu-container">
      <AppSubmenu
        class="layout-menu"
        :items="menu_items"
        :layoutMode="layoutMode"
        :parentMenuItemActive="true"
        :menuActive="menuActive"
        :mobileMenuActive="mobileMenuActive"
        :root="true"
        @menuitem-click="onMenuItemClick"
        @root-menuitem-click="onRootMenuItemClick"
      />
    </div>
  </div>
</template>

<script>
import AppSubmenu from './AppSubmenu.vue';
import { PROYECT_NAME } from '../../../helpers/constants.js';
import { hasPermission } from '../../../helpers/permissions.helper';
import * as permissions from '../../../constants/permissions.const';

export default {
  name: 'AppMenu',
  emits: ['menu-click', 'menuitem-click', 'root-menuitem-click', 'toggle-menu', 'sidebar-mouse-over', 'sidebar-mouse-leave'],
  props: {
    sidebarActive: Boolean,
    sidebarStatic: Boolean,
    layoutMode: String,
    menuActive: Boolean,
    mobileMenuActive: Boolean,
  },
  data() {
    return {
      app: PROYECT_NAME,
    };
  },
  methods: {
    onSidebarMouseOver() {
      if (this.layoutMode === 'sidebar' && !this.sidebarStatic) {
        this.$emit('sidebar-mouse-over');
      }
    },
    onSidebarMouseLeave() {
      if (this.layoutMode === 'sidebar' && !this.sidebarStatic) {
        setTimeout(() => {
          this.$emit('sidebar-mouse-leave');
        }, 250);
      }
    },
    onToggleMenu(event) {
      this.$emit('toggle-menu', event);
    },
    onMenuClick(event) {
      this.$emit('menu-click', event);
    },
    onMenuItemClick(event) {
      this.$emit('menuitem-click', event);
    },
    onRootMenuItemClick(event) {
      this.$emit('root-menuitem-click', event);
    },
  },
  components: { AppSubmenu },
};
</script>
<script setup>
import { onMounted, ref } from 'vue';
const menu_items = ref([
  {
    label: 'Inicio',
    icon: 'fa fa-house',
    items: [
      {
        label: 'Asistencias',
        icon: 'pi pi-chart-line',
        to: { name: 'dashboardPage' },
        visible: hasPermission([permissions.VIEW_DASHBOARD, permissions.VIEW_DASHBOARD_ASISTENCIA_FUNC]),
      },
      {
        label: 'Medidas de protección',
        icon: 'pi pi-chart-line',
        to: { name: 'dashboardMmppPage' },
        visible: hasPermission([permissions.VIEW_DASHBOARD_MMPP]),
      },
      {
        label: 'Cámara Gesell',
        icon: 'pi pi-chart-line',
        to: { name: 'dashboardGesellPage' },
        visible: hasPermission([permissions.VIEW_DASHBOARD_GESSEL]),
      },
    ],
  },
  {
    label: 'Funcionarios',
    icon: 'fa-solid fa-user-tie',
    to: { name: 'funcionariosPage' },
    visible: hasPermission([permissions.VIEW_LIST_FUNCIONARIOS]),
  },
  {
    label: 'Personas',
    icon: 'fa-solid fa-user',
    to: { name: 'personasPage' },
    visible: hasPermission([permissions.VIEW_LIST_PERSONAS]),
  },
  {
    label: 'Convenios',
    icon: 'fa-solid fa-building-columns',
    class: 'custom__icon-size-panel',
    visible: hasPermission([permissions.VIEW_LIST_ESTRUCTURA]),
    items: [
      {
        label: 'Instituciones',
        icon: 'fa-solid fa-landmark',
        to: { name: 'institucionPage' },
        visible: hasPermission([permissions.VIEW_LIST_ESTRUCTURA]),
      },
      {
        label: 'Entidades',
        icon: 'fa-solid fa-landmark',
        to: { name: 'entidadesPage' },
        visible: hasPermission([permissions.VIEW_LIST_ESTRUCTURA]),
      },
      {
        label: 'Oficinas',
        icon: 'fa-solid fa-landmark',
        to: { name: 'oficinasPage' },
        visible: hasPermission([permissions.VIEW_LIST_ESTRUCTURA]),
      },
      {
        label: 'Divisiones',
        icon: 'fa-solid fa-landmark',
        to: { name: 'divisionesPage' },
        visible: hasPermission([permissions.VIEW_LIST_ESTRUCTURA]),
      },
      {
        label: 'Unidad Organizacional',
        icon: 'fa-solid fa-landmark',
        to: { name: 'unidadesPage' },
        visible: hasPermission([permissions.VIEW_LIST_ESTRUCTURA]),
      },
    ],
  },
  {
    label: 'Medidas de protección',
    icon: 'fa fa-clipboard-list',
    visible: hasPermission([
      permissions.VIEW_LIST_REQUERIMIENTOS,
      permissions.VIEW_LIST_SOLICITUDES,
      permissions.VIEW_LIST_EVALUACIONES,
      permissions.VIEW_LIST_MMPPS,
    ]),
    items: [
      {
        label: 'Bandeja de requerimientos',
        icon: 'fa fa-file-pen',
        to: { name: 'requerimientosPage' },
        visible: hasPermission([permissions.VIEW_LIST_REQUERIMIENTOS]),
      },
      {
        label: 'Bandeja de solicitudes de trabajo',
        icon: 'fa fa-clipboard-list',
        to: { name: 'trabajosPage' },
        visible: hasPermission([permissions.VIEW_LIST_SOLICITUDES]),
      },
      {
        label: 'Solicitudes de trabajo UPAVT',
        icon: 'fa fa-clipboard-list',
        to: { name: 'bandejaUpavtPage' },
        visible: hasPermission([permissions.VIEW_LIST_EVALUACIONES]),
      },
      {
        label: 'Medidas de protección',
        icon: 'fa fa-clipboard-list',
        to: { name: 'mmppPage' },
        visible: hasPermission([permissions.VIEW_LIST_MMPPS]),
      },
    ],
  },

  {
    label: 'Medidas de protección - Instituciones',
    icon: 'fa fa-clipboard-list',
    visible: hasPermission([permissions.VIEW_MMPP_LIST_EXTERNO]),
    items: [
      {
        label: 'Medidas de protección',
        icon: 'pi pi-book',
        to: { name: 'mmppExternosPage' },
        visible: hasPermission([permissions.VIEW_MMPP_LIST_EXTERNO]),
      },
      {
        label: 'Medidas de proteccion - Funcionario',
        icon: 'pi pi-book',
        to: { name: 'mmppExternosFuncPage' },
        visible: hasPermission([permissions.VIEW_MMPP_LIST_EXTERNO]),
      },
    ],
  },
  {
    label: 'Asistencias',
    icon: 'fa fa-clipboard-list',
    visible: hasPermission([permissions.VIEW_LIST_ASISTENCIAS, permissions.VIEW_LIST_ASISTENCIAS_FUNCIONARIOS, permissions.INSERT_ASISTENCIA]),
    items: [
      {
        label: 'Bandeja de asistencias',
        icon: 'pi pi-book',
        to: { name: 'bandejaAsistenciasPage' },
        visible: hasPermission([permissions.VIEW_LIST_ASISTENCIAS]),
      },
      {
        label: 'Crear requerimiento de asistencia',
        icon: 'pi pi-book',
        to: { name: 'requerimientoAsistPage' },
        visible: hasPermission([permissions.INSERT_ASISTENCIA]),
      },
      {
        label: 'Asistencias - UPAVT',
        icon: 'pi pi-book',
        to: { name: 'asistenciaUpavtFunc' },
        visible: hasPermission([permissions.VIEW_LIST_ASISTENCIAS_FUNCIONARIOS]),
      },
    ],
  },
  {
    label: 'Agendamiento',
    icon: 'fa fa-calendar',
    visible: hasPermission([permissions.VIEW_LIST_AGENDAMIENTO]),
    items: [
      {
        label: 'Agendamiento - UPAVT',
        icon: 'fa fa-calendar',
        to: { name: 'agendamientoPage' },
        visible: hasPermission([permissions.VIEW_LIST_AGENDAMIENTO]),
      },
      {
        label: 'Agendamiento - Cámara Gesell',
        icon: 'fa fa-calendar',
        to: { name: 'agendamientoGesselPage' },
        visible: hasPermission([permissions.VIEW_LIST_AGENDAMIENTO]),
      },
      {
        label: 'Listado Cámara Gesell',
        icon: 'fa fa-calendar',
        to: { name: 'gesellPage' },
        visible: hasPermission([permissions.VIEW_LIST_AGENDAMIENTO_GESELL]),
      },
    ],
  },
  {
    label: 'Catalogos',
    icon: 'fa fa-cubes',
    id: 'catalogos',
    visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
    items: [
      {
        label: 'Actividades de cámara gesell',
        icon: 'pi pi-book',
        to: { name: 'actividadesGessel' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Actividades de instituciones',
        icon: 'pi pi-book',
        to: { name: 'actividadesInstPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Actividades Área Psicología Mmpp',
        icon: 'pi pi-book',
        to: { name: 'psicologicasPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Actividades Área Social Mmpp',
        icon: 'pi pi-book',
        to: { name: 'socialesPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Actividades de asistencia',
        icon: 'pi pi-book',
        to: { name: 'actividadesAsistPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Areas de atención',
        icon: 'pi pi-book',
        to: { name: 'areasPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Etapas',
        icon: 'pi pi-book',
        to: { name: 'etapasPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Eventos',
        icon: 'pi pi-book',
        to: { name: 'eventosPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Formularios',
        icon: 'pi pi-book',
        to: { name: 'formularioPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Grupos vulnerables',
        icon: 'pi pi-book',
        to: { name: 'grupoVulnerablePage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Generos',
        icon: 'pi pi-book',
        to: { name: 'generoPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Incidencias',
        icon: 'pi pi-book',
        to: { name: 'incidenciaPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Medidas de protección',
        icon: 'pi pi-book',
        to: { name: 'mmppCatalogoPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Motivos de solicitud de plazo',
        icon: 'pi pi-book',
        to: { name: 'motivoPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Plantillas',
        icon: 'pi pi-book',
        to: { name: 'plantillasPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Respuestas de asistencias',
        icon: 'pi pi-book',
        to: { name: 'actividadesAsistRespPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Situaciones de peligro',
        icon: 'pi pi-book',
        to: { name: 'situacionPeligro' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Sub etapas',
        icon: 'pi pi-book',
        to: { name: 'subEtapasPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },

      {
        label: 'Tipo de agenda',
        icon: 'pi pi-book',
        to: { name: 'agendaPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Tipos de contactos',
        icon: 'pi pi-book',
        to: { name: 'tipoContactoPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Tipos de documentos',
        icon: 'pi pi-book',
        to: { name: 'tipoDocumentoPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },

      {
        label: 'Tipos de victimas',
        icon: 'pi pi-book',
        to: { name: 'tipoVictimaPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
      {
        label: 'Ubicación de cámara gesell',
        icon: 'pi pi-book',
        to: { name: 'gesellUbicacionesPage' },
        visible: hasPermission([permissions.VIEW_LIST_CATALOGOS]),
      },
    ],
  },
]);
</script>

<style lang="scss" scoped>
.logo-inst {
  width: 2.7rem !important;
  height: 2.5rem !important;
}

.sidebar-logo {
  padding: 8px;
}

.custom__icon-size-panel {
  & svg {
    height: 25px;
    width: 24px;
  }
}
</style>
