import axios from 'axios';
import { SEGURIDAD_API_URL } from '../helpers/constants';

export const apiLoginInstance = axios.create({
  baseURL: SEGURIDAD_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});
