<template>
  <div style="z-index: 10000">
    <Avatar shape="circle" class="change-theme avatar-custom relative" @click="theme.changeDarkMode()">
      <i
        v-tooltip="theme.darkMode ? 'Activar modo día' : 'Activar modo noche'"
        class="pi m-0 animate__animated"
        :class="{ 'pi-moon': theme.darkMode, 'pi-sun': !theme.darkMode, animate__rotateIn: theme.isLoading }"
        :style="{ fontSize: theme.darkMode ? '1.45rem' : '1.6rem' }"
      ></i>
    </Avatar>
  </div>
</template>

<script>
import { useThemeStore } from '@/store/theme';

export default {
  setup() {
    const theme = useThemeStore();
    return {
      theme,
    };
  },
};
</script>

<style lang="scss">
.avatar-custom {
  width: 2.5rem;
  height: 2.5rem;
  right: 0.25rem;
  cursor: pointer;
}

.change-theme {
  &:hover {
    background-color: var(--surface-200);
  }
}
</style>
