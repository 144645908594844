<template>
  <Sidebar class="p-sidebar-sm z-index-10000" v-model:visible="displayModal" :dismissable="false" position="right" width="100%">
    <template #header>
      <p class="font-semibold text-500 uppercase px-2 text-sm text-900">Aprobaciones del documento</p>
    </template>
    <div class="grid grid-nogutter">
      <template v-if="firmasList.length > 0">
        <div v-for="(funcionario, idx) in firmasList" :key="idx" class="field col-12 card p-3 px-4">
          <div class="flex flex-column gap-2">
            <div class="flex gap-2">
              <div class="flex align-items-center">
                <img :src="funcionario.fotoUrl" class="profile_image" :onerror="(ev) => onImageError(ev)" alt="Image" />
              </div>
              <div class="flex flex-column justify-content-center gap-1">
                <p class="m-0 text-center text-sm font-bold">{{ funcionario.nombreCompleto || funcionario.nombreFuncionario || '-' }}</p>
                <p v-if="funcionario.cargo" class="text-center m-0 text-sm">{{ funcionario.cargo || 'SIN CARGO' }}</p>
                <p class="text-center text-sm m-0">{{ funcionario.numeroDocumento }}</p>
                <p class="text-center text-sm m-0">{{ funcionario.fecha }}</p>
                <div class="flex justify-content-center mt-2">
                  <Tag v-if="funcionario.aprobado" class="tag_custom" value="Aprobado" severity="success" />
                  <Tag v-else class="tag_custom" value="Sin aprobar" severity="danger" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text-center flex flex-column gap-2 border-1 border-200 border-round-sm p-3">
          <i class="text-primary text-5xl pi pi-exclamation-triangle" />
          <span class="text-sm font-semibold">No existen aprobaciones por ciudadanía digital </span>
        </div>
      </template>
    </div>
    <template #footer>
      <div class="flex gap-3 flex-1">
        <Button title="Cerrar" label="Cerrar" class="p-button-outlined w-full p-button-danger" type="button" icon="pi pi-times" @click="displayModal = false" />
      </div>
    </template>
  </Sidebar>
</template>

<script>
import { computed } from '@vue/runtime-core';
import { onImageError } from '../../../helpers/foto.helper';
import useGenericDialog from '../../../shared/hooks/useGenericDialog';
export default {
  name: 'DialogSignatureList',
};
</script>
<script setup>
const props = defineProps({
  signatures: {},
});
const { displayModal, openDialog, closeDialog } = useGenericDialog();
const firmasList = computed(() =>
  Array.isArray(props.signatures)
    ? props.signatures.map((doc) => ({
        ...doc,
        fotoUrl: `${import.meta.env.VITE_RRHH_API}/api/personas/fotosegip/${doc.foto}`,
      }))
    : []
);

defineExpose({
  openDialog,
  closeDialog,
});
</script>
<style lang="scss">
.custom {
  z-index: 1002;
}
.z-index-10000 {
  z-index: 10000;
}
.tag_custom {
  font-size: 0.75rem;
  text-align: center;
  text-transform: uppercase;
  padding: 0.5rem 0.75rem;
}
</style>
