import { createRouter, createWebHistory } from 'vue-router';
import { INSTITUCION_NAME } from '../helpers/constants';
import shared_routes from '../shared/router/';
import authRoutes from '../modules/auth/router';
import requerimientosRoutes from '../modules/requerimientos/router';
import catalogosRoutes from '../modules/catalogos/router';
import dashboardRoutes from '../modules/dashboard/router';
import estructuraRoutes from '../modules/estructura/router';
import funcionariosRoutes from '../modules/funcionarios/router';
import personasRoutes from '../modules/personas/router';
import trabajosRoutes from '../modules/trabajos/router';
import reqFiscalRoutes from '../modules/jl/router';
import actividadesRoutes from '../modules/actividadesBandeja/router';
import mmppRoutes from '../modules/mmpp/router';
import mmppExternosRoutes from '../modules/mmppExternos/router';
import asistenciasRoutes from '../modules/asistencias/router';
import agendamientoRoutes from '../modules/agendamiento/router';
import ThemeLayout from '../shared/layouts/theme.layout.vue';
import { isAuthenticatedGuard, isPublic, isPublicFiscal } from './auth.guard';

const routes = [
  {
    path: '/auth',
    name: 'authPages',
    beforeEnter: [isPublic],
    ...authRoutes,
  },
  {
    //Main layout theme wrapper
    path: '/',
    beforeEnter: [isAuthenticatedGuard],
    component: ThemeLayout,
    redirect: { name: 'loginPage' },
    children: [
      //Dashboard router
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('../shared/layouts/empty.layout.vue'),
        children: [...dashboardRoutes],
      },
      //Requerimientos router
      {
        path: 'requerimientos',
        name: 'requerimientos',
        component: () => import('../shared/layouts/empty.layout.vue'),
        children: [...requerimientosRoutes],
      },
      {
        path: 'estructura_org',
        name: 'estructura_org',
        component: () => import('../shared/layouts/empty.layout.vue'),
        children: [...estructuraRoutes],
      },
      {
        path: 'funcionarios',
        name: 'funcionarios',
        component: () => import('../shared/layouts/empty.layout.vue'),
        children: [...funcionariosRoutes],
      },
      {
        path: 'personas',
        name: 'personas',
        component: () => import('../shared/layouts/empty.layout.vue'),
        children: [...personasRoutes],
      },
      {
        path: 'catalogos',
        name: 'catalogos',
        component: () => import('../shared/layouts/empty.layout.vue'),
        children: [...catalogosRoutes],
      },
      {
        path: 'solicitud_trabajo',
        name: 'trabajos',
        component: () => import('../shared/layouts/empty.layout.vue'),
        children: [...trabajosRoutes],
      },
      {
        path: 'bandeja_upavt',
        name: 'bandejaUpavt',
        component: () => import('../shared/layouts/empty.layout.vue'),
        children: [...actividadesRoutes],
      },
      {
        path: 'bandeja_mmpp',
        name: 'mmpp',
        component: () => import('../shared/layouts/empty.layout.vue'),
        children: [...mmppRoutes],
      },
      {
        path: 'inst_bandeja_mmpp',
        name: 'mmppExternos',
        component: () => import('../shared/layouts/empty.layout.vue'),
        children: [...mmppExternosRoutes],
      },
      {
        path: 'asistencias',
        name: 'asistencias',
        component: () => import('../shared/layouts/empty.layout.vue'),
        children: [...asistenciasRoutes],
      },
      {
        path: 'agendamiento',
        name: 'agendamiento',
        component: () => import('../shared/layouts/empty.layout.vue'),
        children: [...agendamientoRoutes],
      },
    ],
  },
  // PUBLIC ROUTES
  {
    // REQUERIMIENTO FISCAL MMPP
    path: '/login/jl',
    name: 'reqFiscalPages',
    component: () => import('../shared/layouts/empty.layout.vue'),
    beforeEnter: [isPublicFiscal],
    children: [
      {
        path: '',
        name: 'reqFiscalPages',
        redirect: { name: 'reqFiscalLogin' },
      },
      {
        path: 'asistencias',
        name: 'asistenciasLogin',
        meta: { title: 'REQUERIMIENTO FISCAL DE ASISTENCIAS  - DPVTMMP ' },
        component: () => import('../modules/jl/pages/LoginAsistenciasJLPage.vue'),
      },
      {
        path: 'sesion_finalizada',
        name: 'reqFiscalFinPage',
        meta: { title: 'DPVTMMP - JL2' },
        component: () => import('../modules/jl/pages/SesionFinPage.vue'),
      },
      {
        path: 'mmpp/:casoId',
        name: 'reqFiscalLogin',
        meta: { title: 'REQUERIMIENTO FISCAL - DPVTMMP ' },
        component: () => import('../modules/jl/pages/LoginJLFiscal.vue'),
      },
    ],
  },
  {
    path: '/documento',
    name: 'verDocumento',
    component: ThemeLayout,
    children: [
      {
        path: ':idFile',
        name: 'documentoVer',
        meta: { title: 'REQUERIMIENTO FISCAL - DPVTMMP ' },
        component: () => import('../modules/aprobacionDocumentos/pages/DocumentoPage.vue'),
        // beforeEnter: [isAuthenticatedGuard],
      },
    ],
  },
  {
    path: '/aprobacion',
    name: 'verificarDocumento',
    component: () => import('../shared/layouts/aprobaciones.layout.vue'),
    children: [
      {
        path: 'verificar/:idFile',
        name: 'documentoVerificarAprobacion',
        meta: { title: 'REQUERIMIENTO FISCAL - DPVTMMP ' },
        component: () => import('../modules/aprobacionDocumentos/pages/AprobacionesDocumentoPage.vue'),
      },
    ],
  },
  {
    // REQUERIMIENTO FISCAL MMPP y ASISTENCIAS
    path: '/jl',
    name: 'fiscal',
    component: () => import('../shared/layouts/fiscal.layout.vue'),
    children: [...reqFiscalRoutes],
  },
  {
    path: '/error',
    ...shared_routes,
  },
  {
    beforeEnter: [isPublic],
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'not-found',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeEach((to) => {
  try {
    if (to.meta) document.title = to.meta?.title || INSTITUCION_NAME;
    else document.title = INSTITUCION_NAME;
  } catch (e) {
    // console.log(e);
  }
});

export default router;
