import { APPROVE_SOLICITUD_DIRECTOR, VIEW_LIST_SOLICITUDES, VIEW_SOLICITUD } from '../../../constants/permissions.const';
import { hasPermissionMiddleware } from '../../../router/auth.guard';

const routes = [
  {
    path: '',
    name: 'trabajos',
    redirect: { name: 'trabajosPage' },
  },
  {
    path: 'list',
    name: 'trabajosPage',
    meta: { title: 'Bandeja de trabajos - DPVTMMP' },
    component: () => import('../pages/TrabajosPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_LIST_SOLICITUDES])],
  },
  {
    path: 'approve/:token',
    name: 'approveTrabajoPage',
    meta: { title: 'Bandeja de trabajos - DPVTMMP' },
    component: () => import('../pages/ApproveTrabajoPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([APPROVE_SOLICITUD_DIRECTOR])],
  },
  {
    path: 'preview',
    name: 'mmppWatchTrabajoPage',
    meta: { title: 'Bandeja de trabajos - DPVTMMP' },
    component: () => import('../pages/TrabajoPreviewPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_SOLICITUD])],
  },
  {
    path: 'conminacion/:token',
    name: 'conminacionTrabajoPage',
    meta: { title: 'Conminación de trabajo - DPVTMMP' },
    component: () => import('../pages/ConminacionTrabajoPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_SOLICITUD])],
  },
  {
    path: 'mmpp',
    name: 'makeMmppPage',
    meta: { title: 'Bandeja de trabajos - DPVTMMP' },
    component: () => import('../pages/MakeMmppPage.vue'),
  },
];

export default routes;
