<template>
  <ul v-if="items" role="menu">
    <template v-for="(item, i) of items">
      <li v-if="visible(item) && !item.separator" :key="item.label || i" :class="[{ 'layout-root-menuitem': root, 'active-menuitem': activeIndex === i && !item.disabled }]" role="menuitem">
        <router-link
          v-if="item.to"
          :to="item.to"
          :style="item.style"
          :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]"
          active-class="active-route"
          :target="item.target"
          exact
          @click="onMenuItemClick($event, item, i)"
          @mouseenter="onMenuItemMouseEnter(i)"
          v-ripple
        >
          <i :class="['layout-menuitem-icon', item.icon]"></i>
          <span class="layout-menuitem-text">{{ item.label }}</span>
          <i v-if="item.items" class="pi pi-fw pi-chevron-down layout-submenu-toggler"></i>
          <Badge v-if="item.badge" :value="item.badge" style="height: 100%"></Badge>
        </router-link>
        <a v-if="!item.to" :href="item.url || '#'" :style="item.style" :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }]" :target="item.target" @click="onMenuItemClick($event, item, i)" @mouseenter="onMenuItemMouseEnter(i)" v-ripple>
          <i :class="['layout-menuitem-icon', item.icon]"></i>
          <span class="layout-menuitem-text">{{ item.label }}</span>
          <i v-if="item.items" class="pi pi-fw pi-chevron-down layout-submenu-toggler"></i>
          <Badge v-if="item.badge" :value="item.badge" style="height: 100%"></Badge>
        </a>
        <div class="layout-menu-tooltip">
          <div class="layout-menu-tooltip-arrow"></div>
          <div class="layout-menu-tooltip-text">{{ item.label }}</div>
        </div>
        <transition name="layout-menu">
          <appsubmenu v-show="activeIndex === i" :items="visible(item) && item.items" :menuActive="menuActive" :layoutMode="layoutMode" :parentMenuItemActive="activeIndex === i" @menuitem-click="$emit('menuitem-click', $event)"></appsubmenu>
        </transition>
      </li>
    </template>
  </ul>
</template>

<script>
import EventBus from '@/event-bus.js';
export default {
  name: 'appsubmenu',
  emits: ['root-menuitem-click', 'menuitem-click'],
  props: {
    items: Array,
    layoutMode: String,
    menuActive: Boolean,
    mobileMenuActive: Boolean,
    root: {
      type: Boolean,
      default: false,
    },
    parentMenuItemActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeIndex: null,
    };
  },
  mounted() {
    EventBus.on('reset-active-index', () => {
      if (this.isSlim() || this.isHorizontal()) {
        this.activeIndex = null;
      }
    });
  },
  methods: {
    onMenuItemClick(event, item, index) {
      if (item.disabled) {
        event.preventDefault();
        return;
      }
      //execute command
      if (item.command) {
        item.command({ originalEvent: event, item: item });
        event.preventDefault();
      }
      if (item.items) {
        event.preventDefault();
      }
      if (this.root) {
        this.$emit('root-menuitem-click', {
          originalEvent: event,
        });
      }
      if (item.items) {
        this.activeIndex = index === this.activeIndex ? null : index;
      } else {
        this.activeIndex = index;

        if (this.layoutMode !== 'sidebar') {
          const ink = this.getInk(event.currentTarget);
          if (ink) {
            this.removeClass(ink, 'p-ink-active');
          }
        }
      }
      this.$emit('menuitem-click', {
        originalEvent: event,
        item: item,
      });
    },
    onMenuItemMouseEnter(index) {
      if (this.root && this.menuActive && (this.isHorizontal() || this.isSlim()) && !this.isMobile()) {
        this.activeIndex = index;
      }
    },
    visible(item) {
      return typeof item.visible === 'function' ? item.visible() : item.visible !== false;
    },
    isMobile() {
      return window.innerWidth <= 991;
    },
    isSlim() {
      return this.layoutMode === 'slim';
    },
    isHorizontal() {
      return this.layoutMode === 'horizontal';
    },
    getInk(el) {
      for (let i = 0; i < el.children.length; i++) {
        if (typeof el.children[i].className === 'string' && el.children[i].className.indexOf('p-ink') !== -1) {
          return el.children[i];
        }
      }
      return null;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
  },
};
</script>

<style lang="scss" scoped>
$background-menu-custom: var(--primary-color);
$background-menu-custom-active: var(--primary-lighter-color);
$text-menu-custom-color: var(--text-color);
$custom-primary-color: var(--primary-color);
$custom-primary-darken-color: var(--text-color);
$custom-primary-lighten-color: var(--primary-lighter-color);
$primary-color: var(--primary-color);
$primary-dark-color: var(--primary-color);
$primary-light-color: var(--primary-lighter-color);
$primary-lighter-color: var(--primary-lighter-color) !important;
$primary-color-text: var(--text-color);
$custom-text-color: var(--text-color);

.layout-wrapper.layout-static .menu-wrapper .sidebar-logo .sidebar-pin {
  border: 2px solid $background-menu-custom;
}

.layout-static .menu-wrapper .layout-menu-container .layout-menu > li > ul li a:hover {
  background-color: #ed6c44 !important;
}

.layout-menu-light,
.layout-topbar-dark {
  .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li > a:hover {
    background-color: $custom-primary-lighten-color !important;
    border-radius: 0.2rem;
    // margin: 0.1rem;
  }

  .layout-topbar .layout-topbar-wrapper .layout-topbar-left .menu-button:hover {
    background-color: $custom-primary-lighten-color !important;
    // background-color: $custom-primary-lighten-color;
  }

  & .layout-sidebar-active .layout-menu-container .layout-menu > li.active-menuitem > ul > li > a.active-route {
    background-color: $custom-primary-lighten-color !important;
  }

  & .menu-wrapper {
    background-color: $background-menu-custom;

    & .sidebar-logo {
      background-color: $custom-primary-color;
    }

    & .layout-menu-container .layout-menu > li.active-menuitem > ul > li > a.active-route {
      color: $custom-primary-color;
    }

    &.layout-sidebar-active .layout-menu-container .layout-menu {
      & li {
        border-radius: 4px;
        margin-left: 0.4rem;
        margin-right: 0.8rem;

        & ul {
          margin-top: 0.15rem;
        }
      }

      & > li > ul li a:hover {
        background: $background-menu-custom-active !important;
        color: $text-menu-custom-color !important;
        opacity: 0.6;
        // border-radius: 0.1rem;
        // font-size: 13px;
        // padding: 12px 20px;
      }

      & > li > ul li a {
        margin-bottom: 0.2rem;
        color: $text-menu-custom-color;
      }

      & > li > a {
        color: $text-menu-custom-color;

        &:hover {
          color: $custom-primary-color;
        }
      }

      & > li.active-menuitem > a {
        background: $background-menu-custom-active;
        color: $custom-primary-color;
        border-radius: 1px;
        // font-size: 13px;
        // padding: 12px 20px;

        &::before {
          background-color: $custom-primary-color;
        }
      }

      & > li.active-menuitem > ul > li.active-menuitem > a:not(.active-route) {
        color: white;
        background-color: $background-menu-custom !important;
      }

      & > li.active-menuitem > ul > li.active-menuitem > a.active-route {
        /*
          color: $text-menu-custom-color;
          background-color: var(-$custom-primary-lighten:-color !important;
          filter: drop-shadow(0px 0px 6px black);
          */

        background: $background-menu-custom-active !important;
        color: $custom-primary-color !important;
        border-radius: 1px;
        // font-size: 13px;
        // padding: 12px 20px;
      }

      & > li.active-menuitem > ul > li.active-menuitem > a.active-route::before {
        background-color: $custom-primary-color;
        height: 100%;
        content: '';
        width: 4px;
        display: block;
        border-radius: 0px 3px 3px 0px;
        position: absolute;
        left: 0;
      }

      & > li.active-menuitem > ul > li > a.active-route {
        color: $text-menu-custom-color;
      }
    }

    & .layout-menu-container .layout-menu {
      & > li {
        padding: 0px;
        padding-top: 0.4rem;

        & > a {
          padding-top: 0.8rem;
          padding-bottom: 0.8rem;
          color: $text-menu-custom-color;

          &.active-route {
            background-color: $custom-primary-lighten-color;
            color: $text-menu-custom-color;
          }

          &::before {
            height: 100%;
          }
        }

        & > a.active-route::before {
          background: $custom-primary-color;
        }

        & > ul li a {
          color: $text-menu-custom-color;
        }

        &.active-menuitem > a {
          color: $text-menu-custom-color;

          &::before {
            background: $custom-primary-color;
          }

          & > ul > li.active-menuitem > a {
            color: $text-menu-custom-color;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .menu-wrapper .layout-menu-container .layout-menu > li > a.active-route {
    background-color: $custom-primary-lighten-color;
  }

  // .layout-topbar .layout-topbar-wrapper .layout-topbar-left .menu-button:hover {
  // }

  // .menu-wrapper .layout-menu-container .layout-menu>li>a:hover {
  //   color: #ed6c44;
  // }

  // .menu-wrapper .layout-menu-container .layout-menu>li>ul li a:hover {
  //   background-color: #ed6c44;
  // }

  // .menu-wrapper .layout-menu-container .layout-menu>li.active-menuitem>ul>li>a.active-route {
  //   color: $text-menu-custom-color;
  //   background-color: $custom-primary-lighten-color  !important;
  // }

  // .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu>li.active-menuitem>a {
  //   background: $background-menu-custom-active;
  //   color: $custom-text-color;
  //   // border-radius: 4px;
  //   font-size: 13px;
  //   padding: 12px 20px;
  // }
}
</style>