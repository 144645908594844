import { victimasInstance } from '../../../api/victimasInstance';

export const getPlantillaById = async (id) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de conseguir plantillas.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .get(`/v1/api/plantillas/view/${id}`)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const getPlantillaApi = async (values) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de listar plantillas.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .post(`/v1/api/plantillas/listFilter/`, values)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const getPlantillasByTipoDocApi = async (values) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de listar plantillas.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .post(`/v1/api/plantillas/listFilter/`, values)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const changePlantillaStateApi = async (id) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de cambiar estado del plantilla.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .patch(`/v1/api/plantillas/status/${id}`)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const insertPlantillaApi = async (values) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de insertar plantilla.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .post(`/v1/api/plantillas/insert`, values)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const updatePlantillaApi = async (values) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de actualizar plantilla.',
    response: {},
    status: 422,
  };
  await victimasInstance
    .put(`/v1/api/plantillas/update/${values.id}`, values)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const deletePlantillaApi = async (id) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de eliminar plantilla.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .delete(`/v1/api/plantillas/delete/${id}`)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const postGeneratePDFApi = async (values) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de listar plantillas.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .post(`/v1/api/plantillas/generate/pdf`, values)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const getViewPDFApi = async (idFile) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de listar plantillas.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .get(`/v1/api/plantillas/base64/pdf/${idFile}`)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const getViewPDFWaterMarkApi = async (idFile) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de listar plantillas.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .get(`/v1/api/plantillas/base64/pdf/marca/${idFile}`)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const getViewPDFRegistroVistaApi = async (idFile) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de ver pdf.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .get(`/v1/api/registro/vista/${idFile}`)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};
