import { seguridadInstance } from '../../../api/seguridadInstance';
import { victimasInstance } from '../../../api/victimasInstance';
import { apiLoginInstance } from '../../../api/loginInstance';
import { APP_ID } from '../../../helpers/constants';

export const onLoginService = async (values) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de login.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .post(`/v1/api/seguridad/login`, values)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const onLoginTokenService = async (values) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de login.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .post(`/v1/api/seguridad/login/token`, values)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const on2LoginService = async (values) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de login 2 Auth.',
    response: {},
    status: 422,
  };

  await seguridadInstance
    .post(`/confirmation`, values)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const getCodePasswordService = async (values) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de recuperar contraseña.',
    response: {},
    status: 422,
  };
  await apiLoginInstance
    .post(`/recover`, values)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const verifyCodeForgotService = async (values) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de recuperar contraseña.',
    response: {},
    status: 422,
  };

  await apiLoginInstance
    .post(`/confirmcode`, values)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const changeForgotPasswordService = async (values) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de recuperar contraseña.',
    response: {},
    status: 422,
  };

  await apiLoginInstance
    .post(`/change`, values)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const refreshTokenService = async (token) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de login 2 Auth.',
    response: {},
    status: 422,
  };

  await seguridadInstance
    .post(`/refresh`, { token })
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const onLogoutService = async (token) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de logout.',
    response: {},
    status: 422,
  };

  await seguridadInstance
    .post(`/logout`, { token })
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const loadCiudadaniaStateApi = async () => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de cargar ciudadania.',
    response: {},
    status: 422,
  };

  await seguridadInstance
    .post(`/ciudadania/${APP_ID}`)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const onLoginCiudadaniaApi = async (values) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de login de ciudadania.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .post(`/v1/api/seguridad/ciudadania/login`, values)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const onLoginJL1Api = async (values) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de login de ciudadania.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .post(`/v1/api/seguridad/fiscal/login`, values)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const onLoginJL1CiudadaniaApi = async (values) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de login de ciudadania.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .post(`/v1/api/seguridad/fiscal/ciudadania/login`, values)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};
