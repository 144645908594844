import { victimasInstance } from './victimasInstance';

export const getCasoByCud = async (cud) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de buscar persona por CI.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .get(`/v1/api/buscar/cud/${cud}`)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const getPersonByCi = async (ci) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de buscar persona por ci.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .get(`/v1/api/buscar/ci/${ci}`)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const getAllCountriesApi = async () => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de buscar paises.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .get(`/v1/api/buscar/paises`)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const getAllDepartamentosApi = async () => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de buscar departamentos.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .get(`/v1/api/buscar/departamentos`)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const getMunicipiosByDeptApi = async (deptId) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de buscar municipios por departamento.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .get(`/v1/api/buscar/municipios/${deptId}`)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const getSearchUbicacionApi = async (val) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de buscar ubicaciones.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .post(`/v1/api/buscar/ubicaciones/`, val)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const getCatalogosApi = async (val) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de cargar catalogos.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .post(`/v1/api/buscar/catalogos/`, val)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const getDeptMunicipiosApi = async () => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de cargar catalogos.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .get(`/v1/api/buscar/departamentos/municipios/`)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const postVerifySendCodeApi = async (values) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de enviar codigo.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .post(`/v1/api/send/code/`, values)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const postVerifyCodeApi = async (values) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de verificar codigo.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .post(`/v1/api/verify/code/`, values)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const getFilesToApproveApi = async () => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de conseguir informes a aprobar.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .get(`/v1/api/mmpp/informe/aprobacion/`)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const getNotificationsApi = async () => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de listar notificaciones.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .get(`/v1/api/notificaciones/`)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};
export const getNotificationsAsistenciasApi = async () => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de listar notificaciones.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .get(`/v1/api/notificaciones/asistencias`)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const getNotificationsMmppApi = async () => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de listar notificaciones.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .get(`/v1/api/notificaciones/mmpp`)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};

export const changeNotificationStatusApi = async (id) => {
  let response = {
    error: true,
    message: 'Existen problemas con el servicio de cambiar estado de notificacion.',
    response: {},
    status: 422,
  };

  await victimasInstance
    .patch(`/v1/api/notificaciones/status/${id}`)
    .then((resp) => {
      response = resp.data;
    })
    .catch((err) => {
      if (err.response) {
        response = err.response.data;
      }
    });

  return new Promise((resolve, reject) => {
    if (response.error) {
      reject(response);
    } else {
      resolve(response);
    }
  });
};
