import { defineStore } from 'pinia';
import { encodeObj, decodeData } from '@/helpers/encode.helper';
import { STORAGE_APP_INFO_KEY, STORAGE_PERMISO_KEY } from '@/helpers/constants';
import { useStorage } from '@vueuse/core';
import { decode, encode } from '../../../helpers/encode.helper';
import { BINNACLE_ID_KEY } from '../../../helpers/constants';
export const useAuthStore = defineStore('auth-info', {
  state: () => {
    return {
      user: useStorage(STORAGE_APP_INFO_KEY, null).value,
      permisos: decodeData(localStorage.getItem(STORAGE_PERMISO_KEY) || {}),
      binnacleId: useStorage(BINNACLE_ID_KEY, null).value,
    };
  },
  getters: {
    getUser: (state) => {
      try {
        const data = decodeData(state.user);
        return data.user;
      } catch {
        return null;
      }
    },
    userFullName: (state) => {
      const user = decodeData(state.user)?.user || undefined;
      if (user?.nombre === undefined && user?.paterno === undefined && user?.materno === undefined) return 'INVITADO';
      return `${user?.nombre} ${user?.paterno} ${user?.materno}`;
    },
    userPermissions: (state) => {
      return state.permisos;
    },
    getBinnacleId: (state) => {
      return decode(state.binnacleId);
    },
  },
  actions: {
    setUser(data) {
      this.user = data;
      localStorage.setItem(STORAGE_APP_INFO_KEY, encodeObj(data));
    },
    setPermisos(data) {
      this.permisos = data;
      localStorage.setItem(STORAGE_PERMISO_KEY, encodeObj(data));
    },
    async setBinnacleId(binnacleId) {
      const binEncode = encode(binnacleId);
      localStorage.setItem(BINNACLE_ID_KEY, binEncode);
      this.binnacleId = binEncode;
    },
  },
});
