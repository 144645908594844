// #region  variables constantes  para mostrar
export const PROYECT_NAME = (import.meta.env.VITE_SYSTEM_NAME || 'SISTEMA-DPVTMMP').toUpperCase();
export const PROYECT_VERSION = import.meta.env.VITE_VERSION || '0.0.1';
export const INSTITUCION_NAME = (import.meta.env.VITE_INSTITUCION || 'MINISTERIO PÚBLICO').toUpperCase();
export const FRONT_PORT = import.meta.env.VITE_PORT || 8080;
export const APP_ID = import.meta.env.VITE_APP_ID || 0;
export const APP_URL = import.meta.env.VITE_DOMAIN;
export const RRHH_API_URL = import.meta.env.VITE_RRHH_API;
export const SEGURIDAD_API_URL = import.meta.env.VITE_SEGURIDAD_API;
export const CIUDADANIA_DIGITAL_URL = import.meta.env.VITE_CIUDADANIA_DIGITAL;
// #endregion

// #region  variables constantes para manipular data del storage
export const STORAGE_APP_INFO_KEY = 'app_info';
export const STORAGE_PERMISO_KEY = 'permisos';
export const BINNACLE_ID_KEY = 'binnacleId';
// #endregion
