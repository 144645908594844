import { VIEW_MMPP_EXTERNO, VIEW_MMPP_LIST_EXTERNO } from '../../../constants/permissions.const';
import { hasPermissionMiddleware } from '../../../router/auth.guard';

const routes = [
  {
    path: '',
    name: 'mmppExternos',
    redirect: { name: 'mmppExternosPage' },
  },
  {
    path: 'list',
    name: 'mmppExternosPage',
    meta: { title: 'Bandeja de medidas de protección' },
    component: () => import('../pages/MmppExternosPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_MMPP_LIST_EXTERNO])],
  },
  {
    path: 'funcionarios_list',
    name: 'mmppExternosFuncPage',
    meta: { title: 'Bandeja de medidas de protección' },
    component: () => import('../pages/MmppExtFuncionarioPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_MMPP_EXTERNO])],
  },
  {
    path: 'preview',
    name: 'mmppExternosPreview',
    meta: { title: 'Medidas de protección' },
    component: () => import('../pages/MmppExtPreviewPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_MMPP_EXTERNO])],
  },
  {
    path: 'actividad',
    name: 'mmppActivityExternos',
    meta: { title: 'Medidas de protección' },
    component: () => import('../pages/MmppAddActivityExtPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_MMPP_EXTERNO])],
  },
  {
    path: 'add_report/:token',
    name: 'mmppAddReportExternosPage',
    meta: { title: 'Bandeja de medidas de protección' },
    component: () => import('../pages/MmppAddInformeExtPage.vue'),
    beforeEnter: [() => hasPermissionMiddleware([VIEW_MMPP_EXTERNO])],
  },
];

export default routes;
